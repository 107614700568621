/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./footer"
import Header from "./header"
import "./layout.css"
import whats from "../images/whats.svg" // Tell webpack this JS file uses this image

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    <script src="https://unpkg.com/react/umd/react.production.min.js" crossOrigin></script>

<script
  src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"
  crossorigin></script>

<script
  src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"
  crossorigin></script>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div className="whatsapp-section">
      <a href="https://api.whatsapp.com/send/?phone=351914128826" target="_blank" >
    <img className="imagem-whats" src={whats} alt="" />
</a>
        </div>
<div>
        {children}
        </div>
        
        
     
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
