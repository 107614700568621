import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import '../styles/header.css'
import $ from 'jquery' // important: case sensitive.
import CloseIcon from '@material-ui/icons/Close';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Squash as Hamburger } from 'hamburger-react'

import ig from "../images/ig.png" // Tell webpack this JS file uses this image
import fb from "../images/fb.png" // Tell webpack this JS file uses this image

import logo from "../images/logo.png" // Tell webpack this JS file uses this image

import menu from "../images/menu.png" // Tell webpack this JS file uses this image
if (typeof window !== `undefined`) {
  var desktopquery = window.matchMedia('(min-width: 992px)');
  var mobilequery = window.matchMedia('(max-width: 991px)');
  }
  function clickMenu(){
  
  if (desktopquery.matches){
  $('#menu-section-section').stop().slideToggle('slow');
  };
  if (mobilequery.matches){
   $('#menu-section-section').stop().slideToggle('slow');
  };
  }
  function closeMenu(){
  if (desktopquery.matches){
  $('#menu-section-section').animate({
  left: '100%'
  });
  };
  if (mobilequery.matches){
     
  
  $('#menu-section-section').animate({
  left: '100%'
  });
  };
  }


const Header = () => {
  return (
    <div>
   <div id="menu-section-section">
      <div className="wrapper">
         <div className="section-menu">
         <div className="cursos">
            <div className="titulo">
            <Link to="/">
            <span className=" palavra color-white inter-bold"> Cursos e Formações
                               
                               <svg version="1.1" className="circulo" id="Camada_1" x="0px" y="0px"
                                  viewBox="0 0 562 189.6"  >
                                  <g>
                                     <path class="st0" d="M554.8,94.7c0.4,0.6,1.4,0.2,1.1-1.4c-0.6-0.4-1.1-0.7-1.7-1C554.4,93.1,554.6,93.9,554.8,94.7z"/>
                                     <path class="st0" d="M555.4,83.2c-0.8-0.8-1.7-1.5-2.6-1.8c-0.9-0.3-1.6-0.1-2,0.8c0.6,0.2,1,0.4,1.3,0.7c0.3,0.2,0.5,0.4,0.8,0.7
                                        c0.5,0.5,1.1,0.9,2.3,1.6c0.2,0.4,0.3,0.8,0.4,1.1c0.1,0.4,0,0.7,0,0.9c-0.1,0.5-0.3,0.8-0.7,0.8c0.1,0.1,0.2,0.3,0.3,0.6
                                        c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.7,0.3,1.3,0.9,1.7c1-2.3,0.3-5.4-1.9-7.6C554.4,82.8,555,83.2,555.4,83.2z"/>
                                     <path class="st0" d="M401.6,14.3l1.1,0.4C402.3,14.5,402,14.4,401.6,14.3z"/>
                                     <path class="st0" d="M294.4,4.9c0.8,0,1.2-0.2,1.2-0.3C294.9,4.7,294.2,4.8,294.4,4.9z"/>
                                     <path class="st0" d="M276,3.1c0.4,0.1,0.7,0.1,1.1,0.1C276.7,3,276.3,2.9,276,3.1z"/>
                                     <path class="st0" d="M401.6,14.3l-2.8-0.9C398.5,13.9,400.2,14,401.6,14.3z"/>
                                     <path class="st0" d="M538.2,67.8c0.5,0.3,0.9,0.5,1.4,0.6c-0.4-0.3-0.8-0.5-1.2-0.8C538.3,67.7,538.3,67.8,538.2,67.8z"/>
                                     <path class="st0" d="M448.5,21.9l0.9,0.2c0.3-0.1,0.6-0.3,0.9-0.5L448.5,21.9z"/>
                                     <path class="st0" d="M483.8,31.5c-0.8-0.3-1.5,0.9-2,1.4c0.6-0.4,1.3-0.5,2-0.3C483.9,32.3,483.9,32,483.8,31.5z"/>
                                     <path class="st0" d="M535.3,58.5l1,0.4C536,58.8,535.6,58.6,535.3,58.5z"/>
                                     <path class="st0" d="M422.9,16.2c0-0.2-0.1-0.4-0.2-0.6C422.7,15.8,422.8,16,422.9,16.2z"/>
                                     <path class="st0" d="M532.1,123.4c0,0.1,0.1,0.1,0.1,0.2C532.3,123.4,532.3,123.3,532.1,123.4z"/>
                                     <path class="st0" d="M558.4,94.7l0.1,0.6c0.3,0.1,0.5,0.1,0.8,0.1L558.4,94.7z"/>
                                     <path class="st0" d="M242.2,2.1c0.1,0,0.1,0,0.2,0C243.3,1.9,242.8,2,242.2,2.1z"/>
                                     <path class="st0" d="M23,132.9c-1.1-1.6,0.3-1,1.6-0.6c1.3,0.4,2.3,0.6,0.5-1.5C25.2,131.6,19.9,129.5,23,132.9z"/>
                                     <path class="st0" d="M537.6,67.1c-0.4-0.3-0.7-0.6-1.1-0.9C537,66.7,537.3,66.9,537.6,67.1z"/>
                                     <path class="st0" d="M25.1,130.8L25.1,130.8l-0.4-0.4C24.9,130.6,25,130.7,25.1,130.8z"/>
                                     <path class="st0" d="M164.8,10.1c0.2,0.2,0.5,0.4,0.9,0.5C165.6,10.4,165.4,10.3,164.8,10.1z"/>
                                     <path class="st0" d="M222.6,6l-1.4-0.1C221.7,6,222.2,6,222.6,6z"/>
                                     <path class="st0" d="M135.5,17c-0.8,0-1.1-0.1-2.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1C134.1,17.2,134.6,17,135.5,17z"/>
                                     <path class="st0" d="M104.9,23.9c0.2,0.1,0.4,0.1,0.6,0.1c0.3-0.1,0.6-0.2,0.9-0.3L104.9,23.9z"/>
                                     <path class="st0" d="M447.4,158c0.7,0.2,1.3,0.5,1.7,0.9C448.4,158,452.7,156.6,447.4,158z"/>
                                     <path class="st0" d="M538.7,67.2c-0.4,0.2-0.7,0.1-1.1-0.1c0.3,0.2,0.5,0.4,0.8,0.6C538.6,67.6,538.8,67.4,538.7,67.2z"/>
                                     <path class="st0" d="M136.3,28.1c-1.6,0.2-3.2,0.5-4.8,0.8C132.9,28.7,134.6,28.4,136.3,28.1z"/>
                                     <path class="st0" d="M227.4,174l1.1-0.1C228.1,173.9,227.7,173.9,227.4,174z"/>
                                     <path class="st0" d="M117.4,32.1c-1-0.3-1.9-0.3-2.9-0.3c-0.2,0.2-0.4,0.3-0.5,0.5L117.4,32.1z"/>
                                     <path class="st0" d="M449.1,158.9C449.1,158.9,449.1,158.9,449.1,158.9C449.1,158.9,449.1,158.9,449.1,158.9z"/>
                                     <path class="st0" d="M479.1,147c-4.4,1.4-1.5,2.4-2.1,3.7c-5.1,2.2-2.8-0.5-4.9-0.9c-3.1,1.5-5.2,3.8-7.7,4.4
                                        c-0.3-0.2-0.8-0.2-0.3-0.7c-3.1,2.4-10.5,2.9-14.2,5.7c-0.3-0.1-0.5-0.2-0.7-0.3c0.9,0.7,1.2,1.6,1.2,2.3c-2,0.5-3.5,2-4.6,1
                                        c1.3-0.3,1.3-0.8,1.8-1.3l-2,1c-2.3-1.2,2.8-1.3,2.5-2.5c-4,1.5-8.6,4-11.9,4.2c-1-1.5,7.4-2.2,2.8-2.7l5.1-0.5
                                        c-4.4-1.7,3-0.5-1.1-2.5c-6.6,1-7.1,2.5-13.7,3.3c-1.3,1.2,0.8,1.3-0.5,2.5c-4.5,1.7-6.5,1-8.2,0.3c-1.7-0.7-3.1-1.4-6.3,0.3
                                        c-1.6,1.4-1.9,3.6-7,3.3l1.4-2.2c-6.4,0.8-3.2,3.3-10.1,3c1.1-0.9,4.9-1.4,3.9-1.5c-4.6-0.8-2.9,1.5-6.2,2l-0.9-1.6
                                        c-6.8,1.2-14.1,3.4-21.7,4.6c0.4-0.5,1.6-1.2-0.2-1.5c-9.5,1.9-20.5,1.9-29.8,1.5l-3.4,1.7c-0.8,0-1.6-0.6-0.7-0.9
                                        c-3.6-0.3-0.1,0.9-1.8,1.6c-2,0.2-2.8-0.7-1.5-1.2c-6.3,1.7-18,3.7-24.8,2.3l1.3-0.5c-5.2-0.4-5.8,3.2-10.7,1.9l0.9-0.3
                                        c-11.4-0.4-24,1-32.6-1.3c-1.7,2.1-11.2-1.1-12,1.8c-6.5-1.6-4.6-2.1-13.7-3.4c-2.4-0.2-3.7,1.7-6.7,1.8l0-1.5l-4.2,1.4l-2.1-2
                                        l-2.8,0.2c1.1,0.2,2.4,0.8,2.2,1.3c-4.5,0.4-10.7-1.8-10.7-2.5c-3.5,0.2-7,0.3-10.5,0.5c0.3-0.6,2-0.7,3.5-0.9
                                        c-5.7,1.1-0.9-1.7-6.1-0.7c0.7,0.4-0.4,0.9-0.6,1.2c-2.5-1.2-8.2-1.7-12.5-1.8l0.9-1c-7.7-1.1-16.3-1.3-24.9-2.5
                                        c3,0.9,2.1,2.6-0.5,2.5l-0.8-1.7c-1.8,0.8,2.6,1-1,1.7c-3.2-0.7-1.9-2.1,0-2.4l-4,0c-0.2-0.5,0.8-0.6,1.1-1.2
                                        c-1.8,0.8-6.5-2.6-9-1.3l-0.1-0.5c-8.5-1.4-12.5-1.6-19.4-2.9c-0.4,1.3-3.1,0.5-5.3,1.2c-5-1.5-10.4-3.8-16.5-5.1
                                        c-4.4-0.4-1.5,0.8-3.6,1.2c0-1.3-6.1-2.7-2.7-2.8c-3.6-0.8-6-1.4-8.6-1.9c-2.6-0.5-5.4-1.1-10.1-2c0.8,0.8,1.9,1.4,1.2,1.6
                                        c-1.7-0.3-3.6-1.7-3.2-2.2l0.5,0c-5.4-0.7-10.9-3.5-16.1-6.3c-5.2-2.8-9.9-5.6-14.3-6.1l0.5,0.1c-2.8-0.8-5.6-1.6-8.4-2.4
                                        c-3.5-2,2.6,0.2,0.3-1.8c-4.6-1.1-7.8-3-10.7-4.9c-2.9-1.9-5.4-3.8-8.8-5.5c-1.2-3.2-5.2-6.2-8.8-9.7c-3.7-3.4-6.9-7.7-7.1-11.2
                                        l-0.7,0.7c-0.4-0.3-0.6-0.6-0.7-0.9c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.5,0.3-1,0.2-1.6c-0.7,0.4-0.1,2-1,1.1c-1.5-3.4-1.9-7.1-1.3-10.8
                                        c0.3-1.8,0.9-3.6,1.7-5.4c0.8-1.7,1.8-3.4,3-5c2.4-3.2,5.4-6.1,8.7-8.8c3.3-2.6,6.9-5,10.6-7.3c2.7-1.7,2.9-3.1,3.3-4.5
                                        c0.5-1.3,1.3-2.7,5.4-3.8l-1.2,2.6c5.2-4.1,11.3-7.1,17.5-9.7c6.2-2.6,12.6-4.9,18.5-7.9c-0.6,0.6-0.5,1.1-1.7,1.5
                                        c3,0.2,9.7-3.2,9.5-4.1c2-0.5,0.7,0.7,2.1,0.8c2.9-0.2,6.3-3.5,8-2.7c0.3,0.1-0.6,0.6-1.1,0.8c1.6-1.1,8.2-2.1,6.1-3
                                        c4.9-0.7,9-3,13.1-3.1c2.2-2.1,8.4-1.2,8.8-3.5c0.1,1.1,2.3,1.1,4.9,0.6c2.6-0.5,5.5-1.5,6.9-2.3c-0.9,0.9,2.8,0.2,1.8,1.3
                                        c3.5-0.7,6.7-1.7,5.8-2.7c7,1.5,12.8-4.4,17-1.6c10.3-2.8,20.1-4.9,29.6-6.5c-0.1,2.2-10.5,1.4-9.8,3.8c8.9-2,14-4.5,23-4.8
                                        c2.4,0.1-1.2,1.1-2,1.5c9-1.8,17.6-1.3,25.6-3.1c0,0.2-0.9,0.5-1.7,0.7c1.6-0.2,3.7,0.1,3.5-0.9l-1.5,0.4c-2.7-1.3,6.2-3.5,6.4-4.3
                                        c-2.6,3.1,8.1,0.3,3.9,4.2c1-0.2,2.3-0.8,2.1-1.5c1.6,0.1,2.1,0.5,0.5,1.3c5.7-0.8,3.6-1.8,8.8-2c0,0.5-1,0.7-1.6,1.3
                                        c2.6-1,5.2-2.1,9.6-1.8c-0.3,0.3,0.3,0.7-1,0.7c7.5-0.4,12.8-2.9,21.1-2c-2.4,2.1,2.8,0.7,3.3,2.5l4.4-0.6l-1.8-0.9
                                        c3.9,0,7.4-1.3,10.7-0.6L290,12l7.3-0.3l-4.1-0.8c4-0.9-0.4-2.4,4.8-1.8c-1.1,0.2,3.9,0.1,5.9,1.2l0,0c1.9-0.5,4-1.1,6.8-0.6
                                        c0.7,0.6-0.7,2-0.2,2.4c2-1.5,9.1-0.1,11.6-1.6c-0.8,0.4-0.9,1.3-0.7,1.5l1.7-1.2c1.8,0.5,2,0.7,1.7,1.5c5.4,0.5-1.6-2.4,4.8-1.1
                                        c-0.5,0.1-0.3,0.3-1.3,0.4c6.4,0.9,12.6-0.1,18.5,1c1.6,2-5.2,0.1-3.3,1.8c8.6,0.3,17.9-0.8,26.1,0.5l-1.5-0.4
                                        c1.4-2.5,6,1.9,10.3,1.2c-1,0.1-1.5,1.3-1.2,1c5.6,1.1,11.6,0.8,20.3,1.2l-1.2,0.5c3.2,1.1,8.7,1.6,12.2,2.5c1.2-0.5,3.2,0,2.1-1.2
                                        c5.2,2.8,10.5,3.2,15.7,3.6c5.2,0.4,10.2,0.8,14.7,3.4c-1.2-0.2-2.9,0-2,0.2c2.8,0.4,5.6,0.8,8.3,1.3c-0.4-1.3-0.9-0.9-2.4-2.2
                                        c1.2-0.4,3.6-0.4,5.7,0.1c-3.9-0.1-0.4,1.8,1,2.8c-0.2-0.4,0.5-0.6,0.4-1c3.8,3.1,1.3-2.1,6.8,0.8l-0.5,0.5c2.3,0.3,4.5,0,6.8,0.9
                                        c0.6,1.5-2.5,0-2.9,1c3.3,0.8,7.2-0.6,9.8,1.6c-1.5-0.1-3.5-0.6-5-0.7c2.8,2.4,3.3,2.1,5.6,3.1l-0.2-0.1c1.8,0.8,3.5,1.6,5.3,2.4
                                        l-1.3-0.8c5.1-2,8.4,2.1,14.6,1.9c-6,0.1-1.3,0.9-2.2,2.1c1.2,0.9,3.1,2.1,2.4,2.3c3-0.2,3.8,2.5,8,2.2c0,0.5,2.6,1.1,1.6,2
                                        c3.4,1-0.4-1.1,0.1-1.5c2.8,0.3,5.5,2,8.5,3.8c3,1.9,6.1,3.8,10,4.6c1.6,0.6,2.9,1,4,1.3c1.1,0.3,2.1,0.6,3.1,0.8
                                        c2,0.5,3.9,1,6.7,2.4c0.7,0.9,0,1-0.7,0.9c-0.8-0.1-1.7-0.4-1.7-0.4c2.4,2.5,4.5,3.8,7.3,5.4c-0.8-0.1-0.5,0.6-0.1,1.6
                                        c1,0.7,1.6,0.7,2.2,0.8c0.6,0.1,1.3,0.3,2.2,1.7c-0.8,0-1.9,0.1-3.2-0.2c2.5,1.6,5.2,3,7.5,5c-0.1,1.2-2.1-0.6-3.3-0.9
                                        c0.3,0.3,0.7,0.6,1,0.9c0.3,0.3,0.7,0.6,1.1,1c0.7,0.7,1.4,1.4,2.1,2c1.4,1.3,2.6,2.4,3.4,2.5c-0.2-0.7-0.5-1.4-0.8-2.1
                                        c0.8,1-0.6,1.2-1.3,0.6c-2.5-1.7-2.2-3.6-1.9-4c0.6,0.5,1.1,1,1.6,1.5c0.2-0.9-0.1-1.8-0.7-2.6c-0.6-0.8-1.5-1.6-2.5-2.4
                                        c-1-0.8-2.2-1.4-3.3-2.1c-1.1-0.7-2.1-1.4-3-2.1c0.9-0.1,1.8-0.3,2.8-0.3c-1.5-0.6-3.1-1.1-4.7-1.6c-0.4-1-0.3-2.4,1.7-1.2
                                        c-2-2.8-2.7-1.2-4.3-3.7c-0.2-0.3,0-0.3,0.3-0.2c-1-0.5-2-0.9-3-1.3c0.8,0,0.4-1.5,1.8-0.2c-2.1-2.1-4.3-3-6.1-3.7
                                        c-1.8-0.7-3.2-1.1-3.7-2.3l0.5,0.1c-3.7-1.7-4-1.4-4.2-0.9c-0.2,0.5-0.2,1.2-3.3,0.6c-0.6-1.7-1.2-3.4-1.9-5.1l-1.4,1
                                        c-0.7-0.4-2.1-1.2-1.6-1.6c-3.2-0.4,0.2,0.8-1.8,1c-3-2.9-10.2-3.7-10.1-6.3c-1.7,0.5,3.2,2.3-1,1.4c-0.2-2.9-4.7-1.4-7.6-3.7
                                        c1.5-0.9,4.5,1,1.5-1.4c-2.8,1.4-6.5-2.8-9.5-3.4c-0.7,1.6-6.5-1.4-11.6-2.2c0.5-0.5-8.9-3.7-13.4-5.4l0.2-0.3
                                        c-1.4,1.5-6,0-9.6-0.5c0.4-1,1.1-1.3,3.1-1.3l-3.3-0.8c-0.9,0.4-2,0.4-4.2,0.2l1.1-1.1c-4.4-0.9-9.1-0.4-14.6-2.4
                                        c-0.6,1.2,3.7,4.4-2.3,4c-0.1-0.7-0.1-1.9,1.5-1.9c-0.5,0-1.9,0.2-2.9-0.3l2.6-0.7c-2.5-1-1.5,0.7-3.6,0.2c0.2-1-1.4-1-0.9-1.7
                                        c0.5,0,1.7,0.7,2.6,0.5c-2.3-0.6-5-1.7-5.8-2.6c0.1,0.6-0.2,1.3-2,1.1c-4.4-0.7-1.3-1.4-3.8-2.4c-1.2,0.5-3.9-0.7-4.2,0.7
                                        c-1.3-0.2-1.4-0.9-1.8-1.4c-2.4,0.2-10.7-1.5-8.4,0.4l0.6,0.2c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0l0,0c-7.7,0.3-15.4-3.1-23-3.3
                                        l0.1-0.5c-4.5,0-9.9-0.8-13.2-0.1c-1.3-0.1-3-1.2-2.4-1.7c-3.4,0-3.9,0.5-8.7-0.7c3.5-0.6-1.1-0.9,3.6-0.3
                                        c-4.4-0.8-5.3-1.7-10.2-0.7c1.7-1.1-3.2-1.8-5.4-1.6l3,1.1c-2.4,0-4.5-0.3-6.6-0.5l1.3-1.6c-9-2.6-16.6,2.4-23.4-1.2
                                        c-2.5,1,4.9,1,2.7,2.3c-4.2-0.4-8.4-2.9-9.8-3c-5.7-1.1-5.4,2.2-11.4,1.5c0.8,0.6,1.4,2.2-3,2.7c-4.2-0.3-5.1-2.5-2.5-3
                                        c1,0,1.5,0.2,1.4,0.4c1.4-0.2,3.1-0.4,1.5-1.1l-0.6,0.6c-2.1-0.6-7.8-0.6-6.5-1.6c-3.2,0.5-1.4,1,1,1.3c-5.4-0.9-8.4-0.1-13.9-0.6
                                        c0.9,0.3,1.7,1,0.8,1c-8.1-0.7-3.6,1.1-9.1,1.5c-3.1-0.9,1.1-2.2-4.4-1.3c-3.4-0.5-0.7-1.5,0.6-2.1c-5,1.2-9.5-0.5-12.4-0.6
                                        l2.7-0.6c-1.6,0.3-2.9,0.4-4.2,0.4l1.6,1.1c-1.6-0.1-2.9-0.1-2.9-0.6c-1.6,0.8,1.3,1.9-1.9,3.1c-2.1-0.5-6.3,0.9-7.1-0.6
                                        c6.6,0.2-0.8-1.5,4.7-2c-1.1,0.2-2.4,0.3-3.9,0.1c-0.2-0.2,0.2-0.3,0.6-0.4C235.9,1,239.4,4,233,4.3c0.8-0.9-1.6-1.6-2.6-1.3
                                        c2.6-0.1,1.3,1.4-1,2.2c-4.2-0.4-4.5,0.8-6.7,0.9l2.5,0.2c-0.8,0.9-2.9,1-5.7,1.4C219.1,7,222,7,220.9,6.7
                                        c-3.1,1.8-7.4-1.1-11.8,0.3c-2.1-0.4-0.3-1.6-2.2-1.9c-4.9,1.6-5.6-0.7-10.8,0.7c1.6,1,1.9,0.7-1.7,2.2c2.9-0.6,5.7-1.1,8.6-1.7
                                        l-5.4,2.2c2.6-0.2,5.4-1.3,7-1.2c-2.6,1.2-2.3,0.9-1,1.8c-3.7-1.4-7,1.7-11.2,0.9l-0.2-2.9c-6.4-0.6-10.9,2.7-19,3l2.7,0.2
                                        c-0.9,1.3-4.9,0.8-7.5,1.6c0.1-1-1.6-1.1-2.8-1.6c0.2,0.7-5.9,0.8-6.3,2.9l-3.8-0.7c-6.6,0.5-6.9,4-13.2,4.7
                                        c1.7-1.4-1.3-1.6,2.8-3.1c-2.1,0.3-4,0.5-3.9,1.2c-1.3-0.4-5.5,2.4-7.6,1.8c-0.8,0.6-0.9,1.6-3.2,2c-0.3-0.1-0.1-0.5-0.1-0.5
                                        c0.1,0.5-3.5,1.4-1.2,2c-3.5-0.9-7.9,0.2-12.2,1.3c-4.3,1.1-8.4,2.3-11.5,1.7c-4.4,1.5-9.6,3.2-13.6,4.5c0.2-0.4-0.1-0.5,0.7-0.6
                                        c-2.9,0.4-3.5,1.3-4.3,2.2c-0.7,1-1.6,1.9-4.8,2.4c-3.7-0.5,3.1-1.9,0.9-1.8c-1.9-2.8-7.4,2.8-12.2,2.6l1-0.5
                                        c-3.6,0.1-7.4,4-12.6,5.8c0,0,0.1-0.4-0.2-0.5c-2.9,2.9-9.2,6.3-13.8,9.1c0.9-1.9,1.9-1.7,0.4-2.4c-2.1,1.2,1.5,1.5-2.5,4
                                        c-1.7,0.1-5,1.2-6.1,0.2c1.3-0.8,2.6-1.5,4-2.2c-2.9-0.5-5.2,2.8-7.4,3.8l-0.1-0.8c-1.5,0.9-2.5,1.8-3.4,2.6
                                        c-0.8,0.8-1.5,1.5-2.1,2.2c-1.3,1.4-2.6,2.8-5.6,5.1l1,0c-1,2-1.9,2.3-2.7,2.6c-0.9,0.3-1.8,0.5-2.9,2.4L17,64
                                        c-0.4,0.6-1,1.4-1.6,2.3c-0.6,0.9-1.4,2-2.1,3.2c-1.5,2.3-2.9,4.9-4.1,7.1c0.1-0.8,0.3-1.7,0.7-2.7c0.4-1,0.8-2,1.4-3
                                        c1.1-2,2.4-4.1,3.4-5.7c-1,1-2.6,2.7-3.9,4.5c-1.3,1.8-2.3,3.7-2.3,4.5c0.5-1.1,1-2.5,1.9-3.2c-0.9,3.7-2.9,7.6-4.9,12.7
                                        c-0.8-0.6-1.6,0-2.3,1.2c-0.3,0.6-0.7,1.5-1,2.4c-0.2,1-0.6,2-0.7,3.1c-0.4,2.2-0.4,4.7-0.5,6.8c0,1.1,0,2.1-0.1,3
                                        c0,0.9-0.2,1.7-0.4,2.3c1,2.6,2.1,5,3.2,7.4c1.2,2.3,2.4,4.7,3.9,6.9c2.8,4.5,6.3,8.7,10.8,12.3c-0.6-2.5,0.1-1.9,1-1.1
                                        c1,0.8,2.3,1.8,3,0.4c2.2,1.4,2.9,2.1,3,2.3c1.7,2,3.5,4,5.4,5.9c1.1,1.7-2.4-0.8-2.6-0.1c2.3,1.7,2.7,2.2,2.8,2.8
                                        c0.1,0.5-0.1,1,1.4,2.3c-0.1-1.3,2.5-0.3,5.1,1.6c-1.1-1.2-2.2-2.3-3.3-3.5c3.8,1.3,5,2.7,6.2,4c1.2,1.4,2.5,2.8,6.4,3.8
                                        c-0.9-0.1-1.8-0.2-2.7-0.3c2,0.7,5.4,2.3,8.9,3.6c3.5,1.3,7.2,2.3,9.4,1.9c0.5,0.6,1.1,1.7-0.6,1.3c4.4,2,9.7,3.2,14.7,4.4
                                        c5,1.2,9.7,2.2,13,4l-0.9,0.5c1.8,0.3,3.4-0.5,5.3,0.9c-0.2,0.3-0.9,0.5-0.1,0.8c1,0,4.9,0,7.1,1.1l-2.2,0.3
                                        c8.8,3.4,14.4,1.6,22.1,2.8c-0.5,2.1,6.5,4.4,10,5.4l-0.2,0.3c10.6,2.4,18.5,1.6,28.5,2.3c2.9,1.7,8.1,2.7,13.8,3.5
                                        c5.7,0.9,11.8,1.6,16.8,2.8c-1.5-0.6,0.4-1.7,2.2-1.7c2.3,0.7,6.6-0.9,6,1.1l-0.8-0.1c1.6,2.1,6.4,2.1,10.2,2.5l0.8-2.6
                                        c4.7,2.3,11.2,2.5,17.1,2.4c5.9,0,11.2-0.2,13.8,1.6c2.4-0.5,5.3-0.4,7.7-1l-0.2,1.4c6.6,0.6,9.8,0.9,18,0.7l-1.7,1
                                        c4.6,0.2,5.9-3.9,10.1-4l1.3,2.1c8,0,16.1,1.8,24-0.2c-0.1,0.3-0.3,0.9-1.6,1c3.8,0,14.6,1.1,13.2-1.8c0.2,1.9,8.3-0.1,12.5-1.1
                                        l-0.6,1.4c3.6-1.9,3.6-2.4,8.7-3.2c1.2,0.1,1.1,1.1-0.5,0.8c4,0.5,3-1.5,7.9-1l-1.8,1c4.1-0.1,3.8-0.6,6-1.8c2.5-0.7,7.8-0.2,8.5,1
                                        c0.5-0.8,2.7-2,5.1-1.9c0.4,0.4-0.5,0.8-0.9,1c7,0.3,10.1-2.5,14.9-1.7c0.1,0.4,0.5,0.6,0.9,0.7c-5.5,0.5-9.9,0.9-9.5,0.7
                                        c0.1,1.3,4.6,1.1,1.2,3.2c3.5-1.3,9.3-4,13.7-3.9c0.2,0.1,0.4,0.2,0.6,0.4l-0.4,0.2c1,0,1.9-0.2,2.7-0.5c1.4,0,2.3,0.1,3.6,0
                                        c0.6,1,5.1-0.1,7.3-0.3c-0.4-1.4,2.5-1.5-0.3-2.3c0.4,0.1-0.4,0.2-1.8,0.5c-0.3,0.1-0.7,0.2-0.9,0.3c0,0,0.1-0.1,0.1-0.1
                                        c-1.4,0.2-3.3,0.5-5.4,0.8c1.7-0.9,3.4-1.7,5.9-1.3c1-1,2.1-2.1,2.8-2.6c3.2,1.5,9.2,1.8,10.2,2.8c6.6-1.7,15.7-3,19.4-6.3
                                        c1.8-0.7,2.3,0.3,3.1,0.6c-1,0.5-2.3,0.7-2.9,1.1c3.7-1.3,11.1-2.4,11.7-4.4c4.9-0.2-2.3,2.2,4.1,0.6l-0.8,0.7
                                        c6.7,0.1,11.7-2.2,16.8-4.6c5.1-2.3,10.3-4.7,17.8-4.8c-1,0.1-1.5,0.1-1.8-0.1c8.2-1.4,16.2-4.4,24.2-7.5
                                        c7.9-3.2,15.7-6.5,23.7-8.9c4.5-1.6,4.4-2.9,6.5-5l1.6,0.8c1.1-1.3,2.3-2.6,3.4-3.9c2.7-2.3,5.6-3.1,8.8-4.3c3.1-1.2,6.4-2.9,9-6.8
                                        l-1.5,0c0.6-0.5,1.4-1.2,2.2-1.9c0.8-0.8,1.6-1.6,2.3-2.3c0.7-0.8,1.3-1.4,1.8-1.8c0.5-0.4,0.8-0.6,1-0.3c0.1-0.4-0.1-1.5,1.1-2.1
                                        l0.7,0.4c1.3-2.4,2.5-4.9,3.4-7.4c1.7-2.6,2.2-2.1,3-1.9c0.4,0.1,0.7,0.1,1.3-0.5c0.3-0.3,0.6-0.7,0.9-1.4c0.3-0.7,0.8-1.5,1.1-2.8
                                        c0.4-1.8,0.3-4.6-0.1-6.6c-0.5-2-1.1-3.3-1.4-2.6c0,0.7-0.2,1.6-0.5,2.3c-0.3,0.7-0.7,1.2-1.1,1.2c0-0.5,0-1,0-1.5l-0.1-1.5
                                        c-1.1-0.3-2.3-1-3.4-0.7c-0.5,0.1-1.1,0.5-1.6,1.1c-0.5,0.6-1,1.6-1.7,2.9c0.2,1.4,0.3,2.9,0.3,4.4c-0.5,0.3-1,0.6-1.5,0.9
                                        c0.4-1.1,0.8-2.2,1-3.3c-0.4,0.4-0.6,0.8-0.8,1.1c-0.2,0.4-0.3,0.7-0.3,1.1c-0.1,0.7,0,1.5,0,2.3c-0.7,0.1-1.3,0.4-1.9,1
                                        c0,0.8,0,1.6-0.1,2.4c0,0.8-0.2,1.6-0.3,2.4c-1-0.1-0.4-2.6-2.4-0.5c-0.2,1.1,0.5,1,1.1,1c0.6,0,1,0.1,0.2,1.7
                                        c-1.1,0.6-1.7,0.5-2.2,0.7c-0.5,0.2-0.7,0.6-1.3,1.8c-0.7-0.1-0.6-0.8-0.1-1.7c-1,2.2-4,4.5-4.1,6.2c-1.3,0.3-2.8,1.6-4.1,2.7
                                        c-1.3,1-2.5,1.7-3.1,0.7c-0.1,0.4-0.7,1.4-1.5,2.4c-0.8,1-1.8,2.1-2.7,2.7c1-2.2-0.8-1.1-2.9,0.1c-2.1,1.2-4.6,2.5-4.8,1.1
                                        c-0.4,0.9-1.1,1.7-2.1,2.5c-1,0.8-2.2,1.5-3.5,2.3c-2.6,1.5-5.6,2.9-7.8,4.6c0.2-0.3,0.6-0.9,1.1-1c-2.8,1.1-3.9,0.8-5.9,1.1
                                        c0.4,1.9-3.8,4.2-5.7,6.2c-4.2-0.4,1.3-2.9-0.5-4.2c0.8,1.4-5.4,3.8-3.8,4.6c-3.1-0.1-2.5,0.3-4.4-0.7c0.3,2.4-2.4,1.3-4.1,3.5
                                        c-4.3,0.2-1.7-1.8-5.8,0.3C475.5,149.1,479.1,147,479.1,147z M373.6,172.7C373.6,172.7,373.6,172.7,373.6,172.7
                                        c0.9-0.2,1.7-0.3,2.2-0.4C375.3,172.4,374.7,172.5,373.6,172.7z M364.4,173.7c1.1-0.1,2.5-0.2,4-0.3c0.4,0.4,0.1,1-0.8,1
                                        C368,174,365.8,173.9,364.4,173.7z M384.9,181.1c-1.4,0.4-2,0.2-2.2-0.1c0.7-0.1,1.5-0.3,2.5-0.5c0-0.1,0.1-0.1,0.2-0.2
                                        C385.3,180.6,385.2,180.9,384.9,181.1z"/>
                                     <path class="st0" d="M387.3,179c0.2-0.1,0.3-0.1,0.6-0.2c-0.3-0.1-0.6-0.2-0.9-0.2c-0.2,0.2-0.3,0.3-0.5,0.5
                                        C386.8,179,387.1,179,387.3,179z"/>
                                     <path class="st0" d="M402.8,14.7l-0.2-0.1c0.2,0.1,0.3,0.2,0.4,0.3C402.9,14.8,402.9,14.8,402.8,14.7z"/>
                                     <polygon class="st0" points="237.7,173.9 237.7,174.3 238.5,174 	"/>
                                     <path class="st0" d="M451.8,29.2c0.1,0.1,0.2,0.3,0.6,0.5C452.3,29.6,452.1,29.4,451.8,29.2z"/>
                                     <path class="st0" d="M472.7,35.3l2.8,1.4C474.3,36,473.5,35.6,472.7,35.3z"/>
                                     <path class="st0" d="M488.4,41.2c0.3-0.2,0.4-0.3,0.5-0.5c0,0,0,0,0,0L488.4,41.2z"/>
                                     <path class="st0" d="M224.4,15.3c-0.2,0-0.4,0.1-0.6,0.1C222.3,15.7,223.3,15.6,224.4,15.3z"/>
                                     <path class="st0" d="M303.9,10.4c-0.9,0.2-1.7,0.5-2.6,0.6C302.7,11.2,303.8,11.2,303.9,10.4z"/>
                                     <path class="st0" d="M136.9,28C136.9,28,136.9,28,136.9,28c-0.2,0-0.3,0-0.5,0.1L136.9,28z"/>
                                     <path class="st0" d="M297.6,10.9c1.4,0.3,2.6,0.3,3.7,0.1C300.2,10.9,298.8,10.7,297.6,10.9z"/>
                                     <path class="st0" d="M547.7,78.1l0.4,0.6c0.1-0.1,0.2-0.1,0.2-0.1L547.7,78.1z"/>
                                     <path class="st0" d="M548.3,78.6l0.6,0.6C548.7,78.9,548.5,78.6,548.3,78.6z"/>
                                     <path class="st0" d="M550.1,80.5c-0.4-0.4-0.8-0.9-1.2-1.3C549.3,79.7,549.6,80.4,550.1,80.5z"/>
                                     <path class="st0" d="M533,63.1l-0.5,0.3C532.6,63.4,532.8,63.3,533,63.1z"/>
                                     <path class="st0" d="M527.4,59.6c0.6,0.5,1.3,1.2,2.1,2c0.8,0.8,1.6,1.5,2.6,2l0.4-0.2C531.2,63.4,529.2,59.6,527.4,59.6z"/>
                                     <path class="st0" d="M505.2,41.2c1.7,0.9,3.4,1.8,5.1,2.7c-0.7-0.9-1.6-2.1,0.8-1.1C505.2,38.2,512.1,44.4,505.2,41.2z"/>
                                     <path class="st0" d="M488.9,34c3,0.8,0.2-0.7,0.5-1C487.3,32.8,486.8,32.8,488.9,34z"/>
                                     <path class="st0" d="M363.3,8.7l0.4-1.2C361.3,7.4,363.6,7.9,363.3,8.7z"/>
                                     <polygon class="st0" points="338.4,13.3 340.6,14.2 341.8,13.1 	"/>
                                     <polygon class="st0" points="324.5,3.4 325.6,3.3 321.6,3.6 	"/>
                                     <path class="st0" d="M110,21.3l1.1,1.4C112.6,22,112.5,20.2,110,21.3z"/>
                                     <path class="st0" d="M24,58.4c-0.6,0-1.6,0.3-2.5,0.8c-0.9,0.5-1.7,1.3-2.1,2.1C21.1,60.1,23.2,59.2,24,58.4z"/>
                                     <polygon class="st0" points="212.4,184.8 210.5,185.2 214,185 	"/>
                                  </g>
                               </svg>
                            </span>
            </Link>
            </div>
         </div>
         <div className="about">
             <div className="titulo">
             <Link to="/about">
             <span className="palavra color-white inter-bold"> Sobre Nós
               
                           
                              <svg version="1.1" id="Camada_1" className="linha"   x="0px" y="0px"
                                 viewBox="0 0 562 189.6"  >
                                 <g>
                                    <path class="st0" d="M556.2,95c0.3-0.4,0.1-1.4-0.7-1.2l-0.6,1.7L556.2,95z"/>
                                    <path class="st0" d="M550.8,91.9c-0.9,0.8-2,2.2-1.4,3.7c0.9-1.6,1.2-0.9,2.2-2.8c0.8-0.2,1.4,0.5,1.3,1.1c0.3-0.4,1.2,0.4,1.7-0.7
                                       c-1-1.3-2.4-1.3-3.7-0.3C550.5,92.7,550.7,92.2,550.8,91.9z"/>
                                    <path class="st0" d="M472.7,89.3l0.5,0.2C473.1,89.4,472.9,89.4,472.7,89.3z"/>
                                    <path class="st0" d="M424.3,88.4c0.4,0,0.5-0.2,0.5-0.4C424.5,88.2,424.2,88.3,424.3,88.4z"/>
                                    <path class="st0" d="M416,86.6c0.2,0.1,0.3,0.1,0.5,0.1C416.3,86.6,416.2,86.5,416,86.6z"/>
                                    <path class="st0" d="M472.7,89.3l-1.3-0.5C471.3,89.4,472.1,89.2,472.7,89.3z"/>
                                    <path class="st0" d="M540,95.9c0.3-0.1,0.5-0.2,0.7-0.4c-0.2,0.1-0.4,0.1-0.7,0.2C540.1,95.7,540.1,95.8,540,95.9z"/>
                                    <path class="st0" d="M494,88l0.4,0c0.1-0.2,0.2-0.4,0.3-0.7L494,88z"/>
                                    <path class="st0" d="M510.4,87.7c-0.4-0.1-0.5,1.3-0.6,2c0.2-0.6,0.5-0.9,0.8-1C510.6,88.5,510.5,88.1,510.4,87.7z"/>
                                    <path class="st0" d="M536.4,90.2l0.5-0.2C536.7,90.1,536.5,90.1,536.4,90.2z"/>
                                    <path class="st0" d="M482.3,87.7c0-0.2-0.1-0.4-0.1-0.5C482.2,87.4,482.2,87.5,482.3,87.7z"/>
                                    <path class="st0" d="M24.4,98.3c0-0.1,0-0.2,0-0.2C24.3,98.1,24.3,98.2,24.4,98.3z"/>
                                    <path class="st0" d="M5.4,91.3l0.3-0.1c0-0.3,0.1-0.5,0.1-0.8L5.4,91.3z"/>
                                    <path class="st0" d="M400.9,84.8C400.9,84.8,401,84.8,400.9,84.8C401.4,84.7,401.2,84.7,400.9,84.8z"/>
                                    <path class="st0" d="M262.1,85.8c1.6,1.3-2.6,1.6-0.2,3C261.7,88.3,264.1,86.7,262.1,85.8z"/>
                                    <path class="st0" d="M539.6,95.7c-0.2,0-0.4,0-0.7,0C539.2,95.8,539.4,95.8,539.6,95.7z"/>
                                    <path class="st0" d="M261.9,88.8C261.9,88.9,261.9,88.9,261.9,88.8l0.2,0.1C262.1,88.9,262,88.9,261.9,88.8z"/>
                                    <path class="st0" d="M366.1,85.6c0.1,0.2,0.2,0.4,0.4,0.6C366.4,86.1,366.3,85.9,366.1,85.6z"/>
                                    <path class="st0" d="M392,87.6l-0.6-0.2C391.6,87.6,391.9,87.6,392,87.6z"/>
                                    <path class="st0" d="M352.6,87.6c-0.3-0.1-0.5-0.3-0.9-0.1c0,0.1,0.1,0.1,0.1,0.2C352,87.5,352.2,87.4,352.6,87.6z"/>
                                    <path class="st0" d="M338.6,87.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.3-0.1,0.4-0.1L338.6,87.6z"/>
                                    <path class="st0" d="M67,97.6c-0.3-0.4-0.5-0.8-0.6-1.3C66.6,97.3,64.5,97.7,67,97.6z"/>
                                    <path class="st0" d="M540,95.1c-0.1,0.4-0.2,0.5-0.4,0.6c0.2,0,0.3,0,0.5-0.1C540.1,95.4,540.1,95.2,540,95.1z"/>
                                    <path class="st0" d="M352,98.7l-2.2-0.2C350.4,98.6,351.2,98.7,352,98.7z"/>
                                    <path class="st0" d="M167.6,97.4l-0.5,0.2C167.3,97.6,167.5,97.5,167.6,97.4z"/>
                                    <path class="st0" d="M343.2,98.7c-0.4-0.5-0.8-0.8-1.3-1c-0.1,0.1-0.2,0.2-0.3,0.4L343.2,98.7z"/>
                                    <path class="st0" d="M66.4,96.3C66.4,96.3,66.4,96.3,66.4,96.3C66.4,96.3,66.4,96.3,66.4,96.3z"/>
                                    <path class="st0" d="M51.7,99.6c2.1,0,1-1.8,1.4-2.9c2.5-0.5,1.2,1.4,2,2.3c1.6-0.5,2.8-2.2,4-2.1c0.1,0.3,0.3,0.4,0,0.8
                                       c1.7-1.5,5-0.1,6.9-1.9c0.1,0.2,0.2,0.3,0.3,0.5c-0.3-0.9-0.4-1.9-0.3-2.5c1,0,1.8-1.2,2.1,0c-0.6,0-0.6,0.5-0.9,0.8l1-0.5
                                       c0.9,1.7-1.4,0.6-1.4,1.9c1.9-0.6,4.2-2,5.7-1.5c0.3,1.7-3.5,0.6-1.5,2.1l-2.3-0.6c1.8,2.7-1.4-0.2,0.2,2.7c3,0.5,3.4-0.9,6.4-0.4
                                       c0.7-0.9-0.2-1.4,0.5-2.3c4.3-1.7,3.4,4.3,6.6,2c0.8-1.1,1.1-3.3,3.4-2.1l-0.8,1.9c2.9,0.2,1.7-2.8,4.8-1.5
                                       c-0.5,0.8-2.3,0.6-1.9,0.9c2,1.5,1.4-1.1,2.9-1.1l0.3,1.7c3.1-0.3,6.6-1.5,10-1.8c-0.2,0.5-0.8,1,0,1.6c4.4-0.8,9.3,0.2,13.5,1.2
                                       l1.6-1.5c0.4,0,0.7,0.7,0.3,0.9c1.6,0.6,0.1-0.9,0.9-1.5c0.9-0.1,1.2,0.8,0.6,1.3c2.9-1.2,8.3-2.7,11.3-1.1l-0.6,0.4
                                       c2.4,0.6,2.7-3,4.9-1.6l-0.4,0.3c5.2,0.6,10.9-0.7,14.8,1.5c0.7-2.1,5.1,0.9,5.4-2c2.9,1.5,2.1,2,6.3,3c1.1,0.1,1.7-1.8,3-2.1
                                       l0,1.5l1.9-1.6l1,1.8l1.3-0.4c-0.5-0.1-1.1-0.7-1-1.1c2-0.7,4.9,1.1,4.9,1.9l4.8-1.3c-0.1,0.6-0.9,0.8-1.5,1.2
                                       c2.5-1.6,0.5,1.6,2.8,0.2c-0.3-0.3,0.2-0.9,0.2-1.2c1.2,0.9,3.8,0.9,5.7,0.5l-0.4,1.1c3.5,0.3,7.4-0.5,11.4-0.5
                                       c-1.4-0.5-1.1-2.3,0.1-2.5l0.5,1.6c0.7-1-1.2-0.6,0.3-1.8c1.5,0.2,1,1.9,0.1,2.4l1.8-0.6c0.1,0.5-0.3,0.7-0.4,1.4
                                       c0.7-1,3.1,1.6,4.2-0.1l0.1,0.5c4,0,5.7-0.5,9-0.6c0.1-1.4,1.3-1.1,2.3-2.2c2.4,0.4,5,1.6,7.8,1.4c2-0.7,0.6-1.2,1.5-2
                                       c0.1,1.3,3,1.2,1.5,2.1c3.4-0.2,4.4-0.3,8.7-1.3c-0.5-0.5-1-0.8-0.7-1.2c0.8-0.2,1.8,0.5,1.7,1.2l-0.2,0.1
                                       c4.9-1.9,11.2,3.7,15.1,1.1l-0.2,0.1l4-1.4c1.8,0.2-1.1,1,0.3,1.8c4.3-2.1,6.8,0.3,10.4-1.1c2.8,3.8,9.8-0.3,13.5,2.1l-0.2-0.9
                                       c1-0.9,1.2,0.5,1.9,0.7c-0.1-0.7-1-0.8-0.4-1.3c8.2-1.1,16.8,1.3,25.2,2.1c3.1,0.2,1.7-4.6,5.6-2.5l-1.1,1.7
                                       c6.2-2,12.6,0.1,18.7-1.6c-0.4,0.4-0.4,0.9-1,0.9c1.3,1.2,4.7,0.1,4.7-0.9c0.9,0.1,0.2,0.9,0.8,1.4c1.3,0.7,3.2-1.5,3.9-0.3
                                       c0.1,0.2-0.4,0.4-0.6,0.5c0.8-0.6,3.9,0.1,3.1-1.2c2.3,0.7,4.3-0.6,6.1,0.2c1.2-1.5,3.9,0.7,4.3-1.4c-0.2,2.3,4,1.8,5.4,0.8
                                       c-0.5,0.7,1.2,0.7,0.7,1.7c1.6,0,3.1-0.5,2.8-1.6c3,2.7,6.1-2.2,7.8,1.3c4.9-1.2,9.4-2,13.7-2.6c-0.2,2.3-4.8,0.2-4.6,2.6
                                       c4.1-0.9,6.5-2.9,10.6-2.3c1.1,0.3-0.6,1-1,1.4c4.1-0.9,8,0.2,11.7-1c0,0.2-0.4,0.4-0.8,0.6c0.7-0.1,1.7,0.3,1.6-0.7l-0.7,0.3
                                       c-1.2-1.5,2.9-3.1,3-3.9c-1.2,2.9,3.6,0.7,1.7,4.5c0.5-0.2,1.1-0.7,1-1.4c0.7,0.2,0.9,0.6,0.2,1.4c2.6-0.6,1.7-1.6,4-1.6
                                       c0,0.5-0.5,0.7-0.7,1.3c1.2-1,2.4-1.9,4.4-1.5c-0.1,0.3,0.1,0.7-0.5,0.7c3.4-0.1,5.8-2.6,9.6-1.6c-1.1,2.2,1.3,0.7,1.5,2.6l2-0.6
                                       l-0.8-0.9c1.8,0,3.3-1.3,4.9-0.7l-1.3,0.8l3.3-0.4l-1.9-0.7c1.8-1-0.2-2.4,2.2-1.8c-0.5,0.2,1.8,0,2.7,1.1c0,0,0,0,0,0
                                       c0.9-0.6,1.8-1.2,3.1-0.8c0.3,0.6-0.3,2.1-0.1,2.5c0.9-1.6,4.1-0.4,5.2-2.1c-0.4,0.4-0.4,1.4-0.3,1.6l0.7-1.3
                                       c0.8,0.4,0.9,0.6,0.8,1.4c2.5,0.2-0.8-2.4,2.1-1.3c-0.2,0.1-0.1,0.3-0.6,0.5c2.9,0.5,5.7-0.8,8.4-0.2c0.8,1.9-2.4,0.5-1.4,2
                                       c3.9-0.3,8.1-2.3,11.8-1.8l-0.7-0.2c0.5-2.7,2.8,1.3,4.7,0.1c-0.5,0.2-0.6,1.5-0.5,1.2c2.6,0.4,5.3-0.7,9.2-1.5l-0.5,0.7
                                       c1.5,0.6,4,0.4,5.6,0.7c0.5-0.7,1.4-0.6,0.9-1.6c5.1,3.9,9.7-2.1,14.1,1.2c-0.5,0-1.3,0.6-0.9,0.6l3.8-0.6
                                       c-0.3-1.2-0.5-0.7-1.3-1.6c0.5-0.7,1.6-1.2,2.5-1.2c-1.7,0.8,0,1.9,0.8,2.5c-0.1-0.4,0.1-0.7,0.1-1.1c2,2.1,0.3-2.3,3.1-0.9
                                       l-0.1,0.6c1-0.3,2-1.1,3.1-0.9c0.5,1.3-1.1,0.6-1.2,1.7c1.5-0.1,3.1-2.5,4.5-1.2c-0.7,0.4-1.6,0.4-2.3,0.7c1.5,1.6,1.7,1.2,2.8,1.4
                                       l-0.1,0l2.6,0.7L508,95c1.9-3.5,3.9-0.6,6.5-2.9c-2.5,2.2-0.4,1.2-0.6,2.8c0.7,0.4,1.7,0.9,1.4,1.3c1.2-1.2,2.1,1,3.8-0.8
                                       c0.1,0.5,1.3,0,1,1.3c1.6-0.4-0.4-0.8-0.2-1.5c2.5-1.7,5.8,2,9.4-0.3c3.1-0.9,3.6-2.6,6.5-3.4c1,0.8-0.8,1.8-0.8,1.8
                                       c1.5,0.7,2.7,0.6,4.2,0c-0.3,0.4,0,0.8,0.5,1.3c1.2-0.1,0.8-1.8,2.3-1.1c-0.3,0.5-0.6,1.4-1.2,2c1.4-0.4,2.7-1.4,4.2-1.8
                                       c0.4,0.8-0.9,1.3-1.4,1.8c1.6-0.4,4.2-0.8,4.7-2.1l-1-0.5c0.6-0.1,0.3,1.2-0.1,1.4c-1.4,1.1-2-0.5-2.1-1l1-0.3
                                       c-1-2.9-4.1,0.5-6.1,0.3l0.9-2.1l-2.1,1.7c-0.4-0.5-0.8-1.7,0.3-2c-1.5-1-1.3,0.7-2.6-0.4c-0.1-0.2-0.1-0.3,0.1-0.4l-1.4,0.6
                                       c0.3-0.4-0.2-1.5,0.6-1.2c-2.6-1.2-4.1,1.6-5.1,0l0.2-0.1c-3.6,0.7-0.3,1.5-3,3.3l-1.8-3.8l-0.4,1.5c-0.4-0.1-1.1-0.2-1-0.8
                                       c-1.4,1,0.2,0.7-0.6,1.7c-1.8-1.4-4.9,0.7-5.3-1.8c-0.6,1.2,1.7,0.9-0.2,1.7c-0.5-2.6-2.2,0.4-3.8-0.8c0.5-1.3,2-0.6,0.4-1.8
                                       c-1,2.3-3.2-0.5-4.5-0.1c-0.1,1.8-3,0.8-5.3,1.4c0.1-0.6-4.3-1-6.4-1.7l0.1-0.3c-0.4,1.8-2.6,1.5-4.2,1.9c0.1-1.1,0.4-1.5,1.2-2
                                       l-1.5,0c-0.4,0.6-0.8,0.9-1.8,1.1l0.4-1.4c-2,0.1-4,1.6-6.6,0.7c-0.1,1.3,2.1,3.6-0.7,4.4c-0.1-0.7-0.2-1.9,0.5-2.2
                                       c-0.2,0.1-0.8,0.6-1.3,0.3l1.1-1.2c-1.2-0.5-0.6,1-1.6,0.9c0-1-0.7-0.7-0.6-1.5c0.2-0.1,0.8,0.4,1.2,0c-1.1-0.2-2.3-0.8-2.8-1.5
                                       c0.1,0.6,0,1.3-0.8,1.5c-2,0.1-0.7-1.2-1.9-1.7c-0.5,0.7-1.8,0-1.8,1.5c-0.6,0-0.7-0.7-0.9-1.1c-1,0.7-4.8,0.2-3.7,1.8l0.3,0.1
                                       c0,0-0.1,0-0.2,0.1c0,0,0,0,0,0h0c-3.4,1.5-7.1-0.8-10.4-0.2l0-0.5c-2,0.6-4.5,0.3-5.9,1.4c-0.6,0-1.4-0.9-1.2-1.5
                                       c-1.5,0.4-1.7,0.9-3.9,0.1c1.5-0.9-0.5-0.8,1.6-0.6c-2-0.4-2.5-1.2-4.6,0.1c0.7-1.3-1.5-1.6-2.5-1.2l1.4,0.9c-1.1,0.2-2,0.1-3,0
                                       l0.5-1.7c-4.1-2-7.4,3.4-10.5,0.1c-1.1,1.2,2.2,0.8,1.3,2.2c-1.9-0.2-3.8-2.6-4.4-2.6c-2.6-0.9-2.4,2.4-5.1,1.8
                                       c0.3,0.6,0.7,2.2-1.3,2.7c-1.9-0.2-2.3-2.5-1.1-3c0.5,0,0.7,0.1,0.7,0.3c0.6-0.2,1.4-0.5,0.7-1.1l-0.2,0.6
                                       c-0.9-0.6-3.5-0.5-2.9-1.5c-1.4,0.6-0.6,1,0.4,1.3c-2.4-0.9-3.8,0-6.3-0.6c0.4,0.3,0.8,1,0.4,1c-3.6-0.7-1.6,1.1-4.1,1.4
                                       c-1.4-0.9,0.5-2.2-2-1.4c-1.5-0.6-0.3-1.6,0.3-2.1c-2.3,1.1-4.2-0.8-5.5-1l1.2-0.5c-0.7,0.3-1.3,0.3-1.9,0.3l0.7,1.2
                                       c-0.7-0.2-1.3-0.2-1.3-0.7c-0.7,0.8,0.6,2-0.9,3c-0.9-0.6-2.8,0.6-3.2-0.9c2.9,0.5-0.3-1.6,2.2-1.8c-0.5,0.2-1.1,0.2-1.8,0
                                       c-0.1-0.2,0.1-0.3,0.3-0.4c-2.8-1.4-1.3,1.8-4.2,1.7c0.4-0.9-0.7-1.7-1.2-1.5c1.2,0,0.6,1.5-0.5,2.2c-1.9-0.7-2,0.5-3,0.4l1.1,0.3
                                       c-0.4,0.9-1.3,0.8-2.6,1c-0.1-0.7,1.2-0.5,0.7-0.8c-1.4,1.6-3.3-1.6-5.3-0.6c-0.9-0.6-0.1-1.7-0.9-2.1c-2.3,1.2-2.4-1.2-4.8-0.3
                                       c0.7,1.2,0.8,0.9-0.9,2.1l3.9-0.8l-2.5,1.7c1.2,0,2.5-0.8,3.2-0.5c-1.2,1-1.1,0.7-0.5,1.7c-1.6-1.8-3.2,1-5.1-0.2l0.1-3
                                       c-2.8-1.3-5,1.5-8.6,0.8l1.2,0.5c-0.5,1.2-2.2,0.2-3.4,0.6c0.1-1-0.6-1.3-1.1-2c0.1,0.7-2.7-0.1-3,1.9l-1.6-1.3
                                       c-2.9-0.5-3.4,2.8-6.2,2.5c0.9-1.1-0.4-1.8,1.5-2.5c-0.9,0-1.8-0.2-1.8,0.5c-0.6-0.6-2.6,1.3-3.5,0.4c-0.4,0.4-0.5,1.5-1.6,1.4
                                       c-0.1-0.2,0-0.5,0-0.5c0,0.5-1.7,0.7-0.7,1.8c-2.9-3.1-8.1,0.2-10.7-2.4c-2.1,0.3-4.5,0.6-6.4,0.7c0.1-0.3,0-0.5,0.4-0.4
                                       c-2.6-0.9-1.6,2.8-4.5,1.8c-1.5-1.6,1.6-0.9,0.6-1.5c-0.4-3.2-3.5,0.4-5.5-1.5l0.5-0.2c-1.5-1.1-3.7,1.2-6.1,1c0,0,0.1-0.3,0-0.5
                                       c-1.7,1.7-4.9,2.2-7.3,2.7c0.7-1.3,1.1-0.7,0.6-2c-1.1,0.2,0.3,2.1-1.8,2.4c-0.7-0.7-2.2-1.3-2.4-2.7l2-0.1c-1-1.8-2.6,0.1-3.6-0.2
                                       l0.1-0.8c-3.1,0.3-3.1,2.2-6.4,2.1l0.3,0.6c-1.8,1.9-1.5-1.1-3.3,0.4l-0.5-2c-1.2,0.5-4.2,1.9-6.3,2.3c1.2-1.5,3.7-2.2,5.2-3.1
                                       c-1.2,0-4,0.1-4.6,1.1c0.5-0.2,1.1-0.7,1.5-0.4c-1.4,1.6-3.3,2.3-5.7,2.3c0.4-3.8-6.1-1-7.8-3.9c-4.2,1.4-8.2-0.6-13.1-0.4
                                       c1.7,3-1.4,0.1-1.1,3.1c-1.1,0.2-1.6,0.1-1.7-0.1l-3.4-1.8c-0.8-0.9,1.1-0.7,1-1.4c-2.5-0.2-1-1.3-2.7-2.1c0.3,1.1-0.9,1.6-2.3,1.1
                                       l2,1.4c-3.5,1.6-3-2.6-6.5-1l1.1-1c-1.8,0.6-6.6,0-8.3,2.4c-0.3-0.4-0.7-1.2,0.1-1.4c-4.3-0.4-9.5,2.6-12.8,1.1l0.3-0.7
                                       c-0.8,0.2-1.4,1.4-2.4,0.7c0.1-0.3,0.3-0.7,0-0.8c-0.4,0.3-2.1,1.3-3.2,0.9l0.9-0.8c-4.2-1-6.4,2-9.9,2.6c0-2.2-3.3-2.9-4.9-3.3
                                       l0.1-0.3c-4.8-0.3-8.2,1.8-12.7,2.7c-2.8-2.4-9.4-1-13.9-2.3c0.7,0.4-0.1,1.7-0.9,1.9c-1.1-0.4-2.9,1.5-2.7-0.5l0.4,0
                                       c-0.8-2-2.9-1.5-4.6-1.5l-0.3,2.6c-4.4-3.8-11.5,1.4-14-2c-1.1,0.7-2.4,0.7-3.4,1.3l0.1-1.4c-2.9-0.4-4.4-0.5-8.1-0.2l0.7-1
                                       c-2.1-0.1-2.6,4.1-4.5,4.2l-0.6-2.1c-3.6,0-7.2-1.8-10.8,0.1c0.1-0.3,0.2-0.9,0.7-1c-1.7-0.1-6.5-1.4-5.9,1.5
                                       c0-1.9-3.7-0.2-5.6,0.6l0.3-1.4c-1.6,1.8-1.7,2.3-4,2.8c-0.5-0.2-0.5-1.1,0.2-0.8c-1.8-0.7-1.4,1.3-3.6,0.6l0.8-0.9
                                       c-1.9-0.1-1.7,0.4-2.7,1.4c-1.2,0.6-3.5-0.4-3.8-1.6c-0.2,0.8-1.3,1.8-2.4,1.4c-0.1-0.5,0.3-0.8,0.5-0.9c-3.1-0.9-4.6,1.6-6.7,0.3
                                       c0-0.5-0.2-0.7-0.4-0.8c2.5,0,4.4,0.1,4.3,0.2c0-1.3-2-1.5-0.4-3.3c-1.6,1-4.3,3.1-6.3,2.5c-0.1-0.1-0.2-0.2-0.3-0.4l0.2-0.1
                                       c-0.4-0.1-0.8,0-1.2,0.2c-0.6-0.2-1-0.4-1.6-0.4c-0.2-1.1-2.3-0.6-3.3-0.6c0.1,1.4-1.2,1.2,0,2.3c-0.2-0.1,0.2-0.2,0.8-0.2
                                       c0.1,0,0.3-0.1,0.4-0.1c0,0,0,0.1-0.1,0.1c0.7,0,1.5-0.1,2.5-0.1c-0.8,0.7-1.6,1.3-2.7,0.5c-0.5,0.9-1.1,1.8-1.4,2.3
                                       c-1.4-2-4-3-4.3-4.2c-3,0.8-7.1,0.5-9,3.2c-0.9,0.3-1-0.8-1.3-1.2c0.5-0.3,1.1-0.2,1.4-0.6c-1.7,0.7-5.1,0.3-5.5,2.1
                                       c-2.2-0.8,1.2-1.7-1.8-1.4l0.4-0.6c-5.9-2.9-9.5,4.7-16,0.9c0.5,0.2,0.7,0.3,0.8,0.6c-7.4-1.7-15.3,1.6-22.6-1.1
                                       c-2.1-0.5-2.3,0.6-3.6,1.5L32.1,88l-2.2,1.7c-3.1,1.3-5.3-3.7-9.2-1.3l0.5,1c-1.4-0.2-4.4,0.6-4.2-0.6c-0.1,0.2-0.5,1.1-1,0.5
                                       l0-0.8L12.5,90c-2.7-0.6,0.4-3.1-3.5-3.3C7.6,87,4.9,89,5.5,89.5C6,89.6,6.9,90,7,90.8l-1.3,0.4c-0.2,2.3-1.3,4.7,2,6.3l2.3-1.4
                                       l0.7,1.1l-1.9,0.1c1.1,1.1,1.8,0.1,2.5-0.5c0.2,0.6,0.5,1,0.9,1.2l2.2-2.2c0.3,0.9-1,1.8,0.5,2.3c1-1-1-2,0.7-2.7
                                       c1.1,1.1,0.8,1.9,2,1c0.2,0.6-0.1,1-0.6,1.2c1.1-0.7,3-0.3,3.6-1.5c1.1,1.3,3.8-0.3,3.7,1.9c0.4-0.5,2-1.7,3-1.8
                                       c-1.9,2.6,3.9,0.2,3.4,3c1.4-2.4,5.1-1.1,7.6-2.4c-0.1,0.2-0.4,0.6-0.6,0.4c1.4,0.2,1.8,0.9,2.7,1.4c0.2-2,2.4-2.4,3.5-3.6
                                       c1.7,1.9-1,2.3-0.5,4.1c-0.1-1.6,3-1.6,2.4-2.9c1.3,1.2,1.1,0.6,1.8,2.2c0.2-2.5,1.2-0.4,2.3-1.9c1.9,1.2,0.5,2.3,2.6,1.5
                                       C53.5,98.8,51.7,99.6,51.7,99.6z M101.3,95.4C101.3,95.4,101.3,95.4,101.3,95.4c-0.4,0.1-0.8,0.1-1,0.1
                                       C100.5,95.5,100.8,95.5,101.3,95.4z M105.5,95.4c-0.5-0.1-1.1-0.1-1.8-0.1c-0.1-0.5,0-1,0.4-1C103.8,94.7,104.8,95,105.5,95.4z
                                       M96.6,85.6c0.7-0.2,0.9,0.1,1,0.4c-0.3,0-0.7,0.1-1.2,0.2c0,0.1-0.1,0.1-0.1,0.2C96.4,86.1,96.5,85.8,96.6,85.6z"/>
                                    <path class="st0" d="M95.4,87.5c-0.1,0-0.1,0.1-0.3,0.1c0.1,0.1,0.3,0.2,0.4,0.3c0.1-0.1,0.2-0.3,0.2-0.4
                                       C95.7,87.5,95.6,87.5,95.4,87.5z"/>
                                    <path class="st0" d="M473.3,89.6L473.3,89.6c0,0,0.1,0.1,0.1,0.2C473.3,89.7,473.3,89.6,473.3,89.6z"/>
                                    <polygon class="st0" points="163,98.1 163,97.7 162.6,98 	"/>
                                    <path class="st0" d="M496.2,94.4c0,0.1,0.1,0.3,0.3,0.4C496.5,94.7,496.4,94.5,496.2,94.4z"/>
                                    <path class="st0" d="M506.2,94.7l1.4,0.5C507,94.9,506.5,94.8,506.2,94.7z"/>
                                    <path class="st0" d="M513.8,95.4c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0,0,0,0L513.8,95.4z"/>
                                    <path class="st0" d="M392.6,97.1c-0.1,0-0.2,0-0.3,0.1C391.6,97.4,392,97.3,392.6,97.1z"/>
                                    <path class="st0" d="M428.6,93.8c-0.4,0.3-0.8,0.5-1.2,0.7C428.1,94.5,428.6,94.6,428.6,93.8z"/>
                                    <path class="st0" d="M352.3,98.7C352.3,98.7,352.3,98.7,352.3,98.7c-0.1,0-0.2,0-0.2,0L352.3,98.7z"/>
                                    <path class="st0" d="M425.8,94.4c0.6,0.3,1.2,0.2,1.7,0C426.9,94.3,426.3,94.2,425.8,94.4z"/>
                                    <path class="st0" d="M546.8,96l0.4,0.1c0-0.1,0-0.2,0-0.2L546.8,96z"/>
                                    <path class="st0" d="M547.2,95.9l0.4-0.2C547.5,95.7,547.3,95.7,547.2,95.9z"/>
                                    <path class="st0" d="M548.5,95.4l-0.9,0.4C547.9,95.7,548.3,95.8,548.5,95.4z"/>
                                    <path class="st0" d="M536.7,95.4l-0.1,0.5C536.7,95.8,536.7,95.6,536.7,95.4z"/>
                                    <path class="st0" d="M533.7,95.5c0.7,0.1,1.8,1.1,2.9,0.8l0.1-0.4C536.1,96.7,534.4,94.7,533.7,95.5z"/>
                                    <path class="st0" d="M520.9,89.2l2.6,0.4c-0.4-0.5-1-1.3,0.1-1.4C520.3,86.6,524.2,89.5,520.9,89.2z"/>
                                    <path class="st0" d="M512.9,88.4c1.4-0.3,0-0.8,0.1-1.1C512.1,87.8,511.9,88,512.9,88.4z"/>
                                    <path class="st0" d="M455.3,88.4l0.1-1.3C454.4,87.3,455.4,87.7,455.3,88.4z"/>
                                    <polygon class="st0" points="444.3,95.1 445.3,95.8 445.8,94.7 	"/>
                                    <polygon class="st0" points="437.8,85.9 438.3,85.8 436.5,86.2 	"/>
                                    <path class="st0" d="M341.1,86.4l0.3,1.6C342.1,87.7,342.3,85.9,341.1,86.4z"/>
                                    <path class="st0" d="M299.3,88c-0.5-0.8-1.6-1.3-2.4-0.5C297.9,87.6,298.8,88.2,299.3,88z"/>
                                    <polygon class="st0" points="174.1,85.4 174.9,84.9 173.3,85.3 	"/>
                                 </g>
                              </svg>
                           </span>
                           </Link>    
            </div>
         </div>
         <div className="wq">
         <div className="titulo">
         <Link to="/WQ">

         <span className=" palavra color-white inter-bold"> Bem-estar Animal
                               
                               <svg version="1.1" className="circulo" id="Camada_1" x="0px" y="0px"
                                  viewBox="0 0 562 189.6"  >
                                  <g>
                                     <path class="st0" d="M554.8,94.7c0.4,0.6,1.4,0.2,1.1-1.4c-0.6-0.4-1.1-0.7-1.7-1C554.4,93.1,554.6,93.9,554.8,94.7z"/>
                                     <path class="st0" d="M555.4,83.2c-0.8-0.8-1.7-1.5-2.6-1.8c-0.9-0.3-1.6-0.1-2,0.8c0.6,0.2,1,0.4,1.3,0.7c0.3,0.2,0.5,0.4,0.8,0.7
                                        c0.5,0.5,1.1,0.9,2.3,1.6c0.2,0.4,0.3,0.8,0.4,1.1c0.1,0.4,0,0.7,0,0.9c-0.1,0.5-0.3,0.8-0.7,0.8c0.1,0.1,0.2,0.3,0.3,0.6
                                        c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.7,0.3,1.3,0.9,1.7c1-2.3,0.3-5.4-1.9-7.6C554.4,82.8,555,83.2,555.4,83.2z"/>
                                     <path class="st0" d="M401.6,14.3l1.1,0.4C402.3,14.5,402,14.4,401.6,14.3z"/>
                                     <path class="st0" d="M294.4,4.9c0.8,0,1.2-0.2,1.2-0.3C294.9,4.7,294.2,4.8,294.4,4.9z"/>
                                     <path class="st0" d="M276,3.1c0.4,0.1,0.7,0.1,1.1,0.1C276.7,3,276.3,2.9,276,3.1z"/>
                                     <path class="st0" d="M401.6,14.3l-2.8-0.9C398.5,13.9,400.2,14,401.6,14.3z"/>
                                     <path class="st0" d="M538.2,67.8c0.5,0.3,0.9,0.5,1.4,0.6c-0.4-0.3-0.8-0.5-1.2-0.8C538.3,67.7,538.3,67.8,538.2,67.8z"/>
                                     <path class="st0" d="M448.5,21.9l0.9,0.2c0.3-0.1,0.6-0.3,0.9-0.5L448.5,21.9z"/>
                                     <path class="st0" d="M483.8,31.5c-0.8-0.3-1.5,0.9-2,1.4c0.6-0.4,1.3-0.5,2-0.3C483.9,32.3,483.9,32,483.8,31.5z"/>
                                     <path class="st0" d="M535.3,58.5l1,0.4C536,58.8,535.6,58.6,535.3,58.5z"/>
                                     <path class="st0" d="M422.9,16.2c0-0.2-0.1-0.4-0.2-0.6C422.7,15.8,422.8,16,422.9,16.2z"/>
                                     <path class="st0" d="M532.1,123.4c0,0.1,0.1,0.1,0.1,0.2C532.3,123.4,532.3,123.3,532.1,123.4z"/>
                                     <path class="st0" d="M558.4,94.7l0.1,0.6c0.3,0.1,0.5,0.1,0.8,0.1L558.4,94.7z"/>
                                     <path class="st0" d="M242.2,2.1c0.1,0,0.1,0,0.2,0C243.3,1.9,242.8,2,242.2,2.1z"/>
                                     <path class="st0" d="M23,132.9c-1.1-1.6,0.3-1,1.6-0.6c1.3,0.4,2.3,0.6,0.5-1.5C25.2,131.6,19.9,129.5,23,132.9z"/>
                                     <path class="st0" d="M537.6,67.1c-0.4-0.3-0.7-0.6-1.1-0.9C537,66.7,537.3,66.9,537.6,67.1z"/>
                                     <path class="st0" d="M25.1,130.8L25.1,130.8l-0.4-0.4C24.9,130.6,25,130.7,25.1,130.8z"/>
                                     <path class="st0" d="M164.8,10.1c0.2,0.2,0.5,0.4,0.9,0.5C165.6,10.4,165.4,10.3,164.8,10.1z"/>
                                     <path class="st0" d="M222.6,6l-1.4-0.1C221.7,6,222.2,6,222.6,6z"/>
                                     <path class="st0" d="M135.5,17c-0.8,0-1.1-0.1-2.1,0.3c0.1,0.1,0.2,0.1,0.4,0.1C134.1,17.2,134.6,17,135.5,17z"/>
                                     <path class="st0" d="M104.9,23.9c0.2,0.1,0.4,0.1,0.6,0.1c0.3-0.1,0.6-0.2,0.9-0.3L104.9,23.9z"/>
                                     <path class="st0" d="M447.4,158c0.7,0.2,1.3,0.5,1.7,0.9C448.4,158,452.7,156.6,447.4,158z"/>
                                     <path class="st0" d="M538.7,67.2c-0.4,0.2-0.7,0.1-1.1-0.1c0.3,0.2,0.5,0.4,0.8,0.6C538.6,67.6,538.8,67.4,538.7,67.2z"/>
                                     <path class="st0" d="M136.3,28.1c-1.6,0.2-3.2,0.5-4.8,0.8C132.9,28.7,134.6,28.4,136.3,28.1z"/>
                                     <path class="st0" d="M227.4,174l1.1-0.1C228.1,173.9,227.7,173.9,227.4,174z"/>
                                     <path class="st0" d="M117.4,32.1c-1-0.3-1.9-0.3-2.9-0.3c-0.2,0.2-0.4,0.3-0.5,0.5L117.4,32.1z"/>
                                     <path class="st0" d="M449.1,158.9C449.1,158.9,449.1,158.9,449.1,158.9C449.1,158.9,449.1,158.9,449.1,158.9z"/>
                                     <path class="st0" d="M479.1,147c-4.4,1.4-1.5,2.4-2.1,3.7c-5.1,2.2-2.8-0.5-4.9-0.9c-3.1,1.5-5.2,3.8-7.7,4.4
                                        c-0.3-0.2-0.8-0.2-0.3-0.7c-3.1,2.4-10.5,2.9-14.2,5.7c-0.3-0.1-0.5-0.2-0.7-0.3c0.9,0.7,1.2,1.6,1.2,2.3c-2,0.5-3.5,2-4.6,1
                                        c1.3-0.3,1.3-0.8,1.8-1.3l-2,1c-2.3-1.2,2.8-1.3,2.5-2.5c-4,1.5-8.6,4-11.9,4.2c-1-1.5,7.4-2.2,2.8-2.7l5.1-0.5
                                        c-4.4-1.7,3-0.5-1.1-2.5c-6.6,1-7.1,2.5-13.7,3.3c-1.3,1.2,0.8,1.3-0.5,2.5c-4.5,1.7-6.5,1-8.2,0.3c-1.7-0.7-3.1-1.4-6.3,0.3
                                        c-1.6,1.4-1.9,3.6-7,3.3l1.4-2.2c-6.4,0.8-3.2,3.3-10.1,3c1.1-0.9,4.9-1.4,3.9-1.5c-4.6-0.8-2.9,1.5-6.2,2l-0.9-1.6
                                        c-6.8,1.2-14.1,3.4-21.7,4.6c0.4-0.5,1.6-1.2-0.2-1.5c-9.5,1.9-20.5,1.9-29.8,1.5l-3.4,1.7c-0.8,0-1.6-0.6-0.7-0.9
                                        c-3.6-0.3-0.1,0.9-1.8,1.6c-2,0.2-2.8-0.7-1.5-1.2c-6.3,1.7-18,3.7-24.8,2.3l1.3-0.5c-5.2-0.4-5.8,3.2-10.7,1.9l0.9-0.3
                                        c-11.4-0.4-24,1-32.6-1.3c-1.7,2.1-11.2-1.1-12,1.8c-6.5-1.6-4.6-2.1-13.7-3.4c-2.4-0.2-3.7,1.7-6.7,1.8l0-1.5l-4.2,1.4l-2.1-2
                                        l-2.8,0.2c1.1,0.2,2.4,0.8,2.2,1.3c-4.5,0.4-10.7-1.8-10.7-2.5c-3.5,0.2-7,0.3-10.5,0.5c0.3-0.6,2-0.7,3.5-0.9
                                        c-5.7,1.1-0.9-1.7-6.1-0.7c0.7,0.4-0.4,0.9-0.6,1.2c-2.5-1.2-8.2-1.7-12.5-1.8l0.9-1c-7.7-1.1-16.3-1.3-24.9-2.5
                                        c3,0.9,2.1,2.6-0.5,2.5l-0.8-1.7c-1.8,0.8,2.6,1-1,1.7c-3.2-0.7-1.9-2.1,0-2.4l-4,0c-0.2-0.5,0.8-0.6,1.1-1.2
                                        c-1.8,0.8-6.5-2.6-9-1.3l-0.1-0.5c-8.5-1.4-12.5-1.6-19.4-2.9c-0.4,1.3-3.1,0.5-5.3,1.2c-5-1.5-10.4-3.8-16.5-5.1
                                        c-4.4-0.4-1.5,0.8-3.6,1.2c0-1.3-6.1-2.7-2.7-2.8c-3.6-0.8-6-1.4-8.6-1.9c-2.6-0.5-5.4-1.1-10.1-2c0.8,0.8,1.9,1.4,1.2,1.6
                                        c-1.7-0.3-3.6-1.7-3.2-2.2l0.5,0c-5.4-0.7-10.9-3.5-16.1-6.3c-5.2-2.8-9.9-5.6-14.3-6.1l0.5,0.1c-2.8-0.8-5.6-1.6-8.4-2.4
                                        c-3.5-2,2.6,0.2,0.3-1.8c-4.6-1.1-7.8-3-10.7-4.9c-2.9-1.9-5.4-3.8-8.8-5.5c-1.2-3.2-5.2-6.2-8.8-9.7c-3.7-3.4-6.9-7.7-7.1-11.2
                                        l-0.7,0.7c-0.4-0.3-0.6-0.6-0.7-0.9c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.5,0.3-1,0.2-1.6c-0.7,0.4-0.1,2-1,1.1c-1.5-3.4-1.9-7.1-1.3-10.8
                                        c0.3-1.8,0.9-3.6,1.7-5.4c0.8-1.7,1.8-3.4,3-5c2.4-3.2,5.4-6.1,8.7-8.8c3.3-2.6,6.9-5,10.6-7.3c2.7-1.7,2.9-3.1,3.3-4.5
                                        c0.5-1.3,1.3-2.7,5.4-3.8l-1.2,2.6c5.2-4.1,11.3-7.1,17.5-9.7c6.2-2.6,12.6-4.9,18.5-7.9c-0.6,0.6-0.5,1.1-1.7,1.5
                                        c3,0.2,9.7-3.2,9.5-4.1c2-0.5,0.7,0.7,2.1,0.8c2.9-0.2,6.3-3.5,8-2.7c0.3,0.1-0.6,0.6-1.1,0.8c1.6-1.1,8.2-2.1,6.1-3
                                        c4.9-0.7,9-3,13.1-3.1c2.2-2.1,8.4-1.2,8.8-3.5c0.1,1.1,2.3,1.1,4.9,0.6c2.6-0.5,5.5-1.5,6.9-2.3c-0.9,0.9,2.8,0.2,1.8,1.3
                                        c3.5-0.7,6.7-1.7,5.8-2.7c7,1.5,12.8-4.4,17-1.6c10.3-2.8,20.1-4.9,29.6-6.5c-0.1,2.2-10.5,1.4-9.8,3.8c8.9-2,14-4.5,23-4.8
                                        c2.4,0.1-1.2,1.1-2,1.5c9-1.8,17.6-1.3,25.6-3.1c0,0.2-0.9,0.5-1.7,0.7c1.6-0.2,3.7,0.1,3.5-0.9l-1.5,0.4c-2.7-1.3,6.2-3.5,6.4-4.3
                                        c-2.6,3.1,8.1,0.3,3.9,4.2c1-0.2,2.3-0.8,2.1-1.5c1.6,0.1,2.1,0.5,0.5,1.3c5.7-0.8,3.6-1.8,8.8-2c0,0.5-1,0.7-1.6,1.3
                                        c2.6-1,5.2-2.1,9.6-1.8c-0.3,0.3,0.3,0.7-1,0.7c7.5-0.4,12.8-2.9,21.1-2c-2.4,2.1,2.8,0.7,3.3,2.5l4.4-0.6l-1.8-0.9
                                        c3.9,0,7.4-1.3,10.7-0.6L290,12l7.3-0.3l-4.1-0.8c4-0.9-0.4-2.4,4.8-1.8c-1.1,0.2,3.9,0.1,5.9,1.2l0,0c1.9-0.5,4-1.1,6.8-0.6
                                        c0.7,0.6-0.7,2-0.2,2.4c2-1.5,9.1-0.1,11.6-1.6c-0.8,0.4-0.9,1.3-0.7,1.5l1.7-1.2c1.8,0.5,2,0.7,1.7,1.5c5.4,0.5-1.6-2.4,4.8-1.1
                                        c-0.5,0.1-0.3,0.3-1.3,0.4c6.4,0.9,12.6-0.1,18.5,1c1.6,2-5.2,0.1-3.3,1.8c8.6,0.3,17.9-0.8,26.1,0.5l-1.5-0.4
                                        c1.4-2.5,6,1.9,10.3,1.2c-1,0.1-1.5,1.3-1.2,1c5.6,1.1,11.6,0.8,20.3,1.2l-1.2,0.5c3.2,1.1,8.7,1.6,12.2,2.5c1.2-0.5,3.2,0,2.1-1.2
                                        c5.2,2.8,10.5,3.2,15.7,3.6c5.2,0.4,10.2,0.8,14.7,3.4c-1.2-0.2-2.9,0-2,0.2c2.8,0.4,5.6,0.8,8.3,1.3c-0.4-1.3-0.9-0.9-2.4-2.2
                                        c1.2-0.4,3.6-0.4,5.7,0.1c-3.9-0.1-0.4,1.8,1,2.8c-0.2-0.4,0.5-0.6,0.4-1c3.8,3.1,1.3-2.1,6.8,0.8l-0.5,0.5c2.3,0.3,4.5,0,6.8,0.9
                                        c0.6,1.5-2.5,0-2.9,1c3.3,0.8,7.2-0.6,9.8,1.6c-1.5-0.1-3.5-0.6-5-0.7c2.8,2.4,3.3,2.1,5.6,3.1l-0.2-0.1c1.8,0.8,3.5,1.6,5.3,2.4
                                        l-1.3-0.8c5.1-2,8.4,2.1,14.6,1.9c-6,0.1-1.3,0.9-2.2,2.1c1.2,0.9,3.1,2.1,2.4,2.3c3-0.2,3.8,2.5,8,2.2c0,0.5,2.6,1.1,1.6,2
                                        c3.4,1-0.4-1.1,0.1-1.5c2.8,0.3,5.5,2,8.5,3.8c3,1.9,6.1,3.8,10,4.6c1.6,0.6,2.9,1,4,1.3c1.1,0.3,2.1,0.6,3.1,0.8
                                        c2,0.5,3.9,1,6.7,2.4c0.7,0.9,0,1-0.7,0.9c-0.8-0.1-1.7-0.4-1.7-0.4c2.4,2.5,4.5,3.8,7.3,5.4c-0.8-0.1-0.5,0.6-0.1,1.6
                                        c1,0.7,1.6,0.7,2.2,0.8c0.6,0.1,1.3,0.3,2.2,1.7c-0.8,0-1.9,0.1-3.2-0.2c2.5,1.6,5.2,3,7.5,5c-0.1,1.2-2.1-0.6-3.3-0.9
                                        c0.3,0.3,0.7,0.6,1,0.9c0.3,0.3,0.7,0.6,1.1,1c0.7,0.7,1.4,1.4,2.1,2c1.4,1.3,2.6,2.4,3.4,2.5c-0.2-0.7-0.5-1.4-0.8-2.1
                                        c0.8,1-0.6,1.2-1.3,0.6c-2.5-1.7-2.2-3.6-1.9-4c0.6,0.5,1.1,1,1.6,1.5c0.2-0.9-0.1-1.8-0.7-2.6c-0.6-0.8-1.5-1.6-2.5-2.4
                                        c-1-0.8-2.2-1.4-3.3-2.1c-1.1-0.7-2.1-1.4-3-2.1c0.9-0.1,1.8-0.3,2.8-0.3c-1.5-0.6-3.1-1.1-4.7-1.6c-0.4-1-0.3-2.4,1.7-1.2
                                        c-2-2.8-2.7-1.2-4.3-3.7c-0.2-0.3,0-0.3,0.3-0.2c-1-0.5-2-0.9-3-1.3c0.8,0,0.4-1.5,1.8-0.2c-2.1-2.1-4.3-3-6.1-3.7
                                        c-1.8-0.7-3.2-1.1-3.7-2.3l0.5,0.1c-3.7-1.7-4-1.4-4.2-0.9c-0.2,0.5-0.2,1.2-3.3,0.6c-0.6-1.7-1.2-3.4-1.9-5.1l-1.4,1
                                        c-0.7-0.4-2.1-1.2-1.6-1.6c-3.2-0.4,0.2,0.8-1.8,1c-3-2.9-10.2-3.7-10.1-6.3c-1.7,0.5,3.2,2.3-1,1.4c-0.2-2.9-4.7-1.4-7.6-3.7
                                        c1.5-0.9,4.5,1,1.5-1.4c-2.8,1.4-6.5-2.8-9.5-3.4c-0.7,1.6-6.5-1.4-11.6-2.2c0.5-0.5-8.9-3.7-13.4-5.4l0.2-0.3
                                        c-1.4,1.5-6,0-9.6-0.5c0.4-1,1.1-1.3,3.1-1.3l-3.3-0.8c-0.9,0.4-2,0.4-4.2,0.2l1.1-1.1c-4.4-0.9-9.1-0.4-14.6-2.4
                                        c-0.6,1.2,3.7,4.4-2.3,4c-0.1-0.7-0.1-1.9,1.5-1.9c-0.5,0-1.9,0.2-2.9-0.3l2.6-0.7c-2.5-1-1.5,0.7-3.6,0.2c0.2-1-1.4-1-0.9-1.7
                                        c0.5,0,1.7,0.7,2.6,0.5c-2.3-0.6-5-1.7-5.8-2.6c0.1,0.6-0.2,1.3-2,1.1c-4.4-0.7-1.3-1.4-3.8-2.4c-1.2,0.5-3.9-0.7-4.2,0.7
                                        c-1.3-0.2-1.4-0.9-1.8-1.4c-2.4,0.2-10.7-1.5-8.4,0.4l0.6,0.2c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0l0,0c-7.7,0.3-15.4-3.1-23-3.3
                                        l0.1-0.5c-4.5,0-9.9-0.8-13.2-0.1c-1.3-0.1-3-1.2-2.4-1.7c-3.4,0-3.9,0.5-8.7-0.7c3.5-0.6-1.1-0.9,3.6-0.3
                                        c-4.4-0.8-5.3-1.7-10.2-0.7c1.7-1.1-3.2-1.8-5.4-1.6l3,1.1c-2.4,0-4.5-0.3-6.6-0.5l1.3-1.6c-9-2.6-16.6,2.4-23.4-1.2
                                        c-2.5,1,4.9,1,2.7,2.3c-4.2-0.4-8.4-2.9-9.8-3c-5.7-1.1-5.4,2.2-11.4,1.5c0.8,0.6,1.4,2.2-3,2.7c-4.2-0.3-5.1-2.5-2.5-3
                                        c1,0,1.5,0.2,1.4,0.4c1.4-0.2,3.1-0.4,1.5-1.1l-0.6,0.6c-2.1-0.6-7.8-0.6-6.5-1.6c-3.2,0.5-1.4,1,1,1.3c-5.4-0.9-8.4-0.1-13.9-0.6
                                        c0.9,0.3,1.7,1,0.8,1c-8.1-0.7-3.6,1.1-9.1,1.5c-3.1-0.9,1.1-2.2-4.4-1.3c-3.4-0.5-0.7-1.5,0.6-2.1c-5,1.2-9.5-0.5-12.4-0.6
                                        l2.7-0.6c-1.6,0.3-2.9,0.4-4.2,0.4l1.6,1.1c-1.6-0.1-2.9-0.1-2.9-0.6c-1.6,0.8,1.3,1.9-1.9,3.1c-2.1-0.5-6.3,0.9-7.1-0.6
                                        c6.6,0.2-0.8-1.5,4.7-2c-1.1,0.2-2.4,0.3-3.9,0.1c-0.2-0.2,0.2-0.3,0.6-0.4C235.9,1,239.4,4,233,4.3c0.8-0.9-1.6-1.6-2.6-1.3
                                        c2.6-0.1,1.3,1.4-1,2.2c-4.2-0.4-4.5,0.8-6.7,0.9l2.5,0.2c-0.8,0.9-2.9,1-5.7,1.4C219.1,7,222,7,220.9,6.7
                                        c-3.1,1.8-7.4-1.1-11.8,0.3c-2.1-0.4-0.3-1.6-2.2-1.9c-4.9,1.6-5.6-0.7-10.8,0.7c1.6,1,1.9,0.7-1.7,2.2c2.9-0.6,5.7-1.1,8.6-1.7
                                        l-5.4,2.2c2.6-0.2,5.4-1.3,7-1.2c-2.6,1.2-2.3,0.9-1,1.8c-3.7-1.4-7,1.7-11.2,0.9l-0.2-2.9c-6.4-0.6-10.9,2.7-19,3l2.7,0.2
                                        c-0.9,1.3-4.9,0.8-7.5,1.6c0.1-1-1.6-1.1-2.8-1.6c0.2,0.7-5.9,0.8-6.3,2.9l-3.8-0.7c-6.6,0.5-6.9,4-13.2,4.7
                                        c1.7-1.4-1.3-1.6,2.8-3.1c-2.1,0.3-4,0.5-3.9,1.2c-1.3-0.4-5.5,2.4-7.6,1.8c-0.8,0.6-0.9,1.6-3.2,2c-0.3-0.1-0.1-0.5-0.1-0.5
                                        c0.1,0.5-3.5,1.4-1.2,2c-3.5-0.9-7.9,0.2-12.2,1.3c-4.3,1.1-8.4,2.3-11.5,1.7c-4.4,1.5-9.6,3.2-13.6,4.5c0.2-0.4-0.1-0.5,0.7-0.6
                                        c-2.9,0.4-3.5,1.3-4.3,2.2c-0.7,1-1.6,1.9-4.8,2.4c-3.7-0.5,3.1-1.9,0.9-1.8c-1.9-2.8-7.4,2.8-12.2,2.6l1-0.5
                                        c-3.6,0.1-7.4,4-12.6,5.8c0,0,0.1-0.4-0.2-0.5c-2.9,2.9-9.2,6.3-13.8,9.1c0.9-1.9,1.9-1.7,0.4-2.4c-2.1,1.2,1.5,1.5-2.5,4
                                        c-1.7,0.1-5,1.2-6.1,0.2c1.3-0.8,2.6-1.5,4-2.2c-2.9-0.5-5.2,2.8-7.4,3.8l-0.1-0.8c-1.5,0.9-2.5,1.8-3.4,2.6
                                        c-0.8,0.8-1.5,1.5-2.1,2.2c-1.3,1.4-2.6,2.8-5.6,5.1l1,0c-1,2-1.9,2.3-2.7,2.6c-0.9,0.3-1.8,0.5-2.9,2.4L17,64
                                        c-0.4,0.6-1,1.4-1.6,2.3c-0.6,0.9-1.4,2-2.1,3.2c-1.5,2.3-2.9,4.9-4.1,7.1c0.1-0.8,0.3-1.7,0.7-2.7c0.4-1,0.8-2,1.4-3
                                        c1.1-2,2.4-4.1,3.4-5.7c-1,1-2.6,2.7-3.9,4.5c-1.3,1.8-2.3,3.7-2.3,4.5c0.5-1.1,1-2.5,1.9-3.2c-0.9,3.7-2.9,7.6-4.9,12.7
                                        c-0.8-0.6-1.6,0-2.3,1.2c-0.3,0.6-0.7,1.5-1,2.4c-0.2,1-0.6,2-0.7,3.1c-0.4,2.2-0.4,4.7-0.5,6.8c0,1.1,0,2.1-0.1,3
                                        c0,0.9-0.2,1.7-0.4,2.3c1,2.6,2.1,5,3.2,7.4c1.2,2.3,2.4,4.7,3.9,6.9c2.8,4.5,6.3,8.7,10.8,12.3c-0.6-2.5,0.1-1.9,1-1.1
                                        c1,0.8,2.3,1.8,3,0.4c2.2,1.4,2.9,2.1,3,2.3c1.7,2,3.5,4,5.4,5.9c1.1,1.7-2.4-0.8-2.6-0.1c2.3,1.7,2.7,2.2,2.8,2.8
                                        c0.1,0.5-0.1,1,1.4,2.3c-0.1-1.3,2.5-0.3,5.1,1.6c-1.1-1.2-2.2-2.3-3.3-3.5c3.8,1.3,5,2.7,6.2,4c1.2,1.4,2.5,2.8,6.4,3.8
                                        c-0.9-0.1-1.8-0.2-2.7-0.3c2,0.7,5.4,2.3,8.9,3.6c3.5,1.3,7.2,2.3,9.4,1.9c0.5,0.6,1.1,1.7-0.6,1.3c4.4,2,9.7,3.2,14.7,4.4
                                        c5,1.2,9.7,2.2,13,4l-0.9,0.5c1.8,0.3,3.4-0.5,5.3,0.9c-0.2,0.3-0.9,0.5-0.1,0.8c1,0,4.9,0,7.1,1.1l-2.2,0.3
                                        c8.8,3.4,14.4,1.6,22.1,2.8c-0.5,2.1,6.5,4.4,10,5.4l-0.2,0.3c10.6,2.4,18.5,1.6,28.5,2.3c2.9,1.7,8.1,2.7,13.8,3.5
                                        c5.7,0.9,11.8,1.6,16.8,2.8c-1.5-0.6,0.4-1.7,2.2-1.7c2.3,0.7,6.6-0.9,6,1.1l-0.8-0.1c1.6,2.1,6.4,2.1,10.2,2.5l0.8-2.6
                                        c4.7,2.3,11.2,2.5,17.1,2.4c5.9,0,11.2-0.2,13.8,1.6c2.4-0.5,5.3-0.4,7.7-1l-0.2,1.4c6.6,0.6,9.8,0.9,18,0.7l-1.7,1
                                        c4.6,0.2,5.9-3.9,10.1-4l1.3,2.1c8,0,16.1,1.8,24-0.2c-0.1,0.3-0.3,0.9-1.6,1c3.8,0,14.6,1.1,13.2-1.8c0.2,1.9,8.3-0.1,12.5-1.1
                                        l-0.6,1.4c3.6-1.9,3.6-2.4,8.7-3.2c1.2,0.1,1.1,1.1-0.5,0.8c4,0.5,3-1.5,7.9-1l-1.8,1c4.1-0.1,3.8-0.6,6-1.8c2.5-0.7,7.8-0.2,8.5,1
                                        c0.5-0.8,2.7-2,5.1-1.9c0.4,0.4-0.5,0.8-0.9,1c7,0.3,10.1-2.5,14.9-1.7c0.1,0.4,0.5,0.6,0.9,0.7c-5.5,0.5-9.9,0.9-9.5,0.7
                                        c0.1,1.3,4.6,1.1,1.2,3.2c3.5-1.3,9.3-4,13.7-3.9c0.2,0.1,0.4,0.2,0.6,0.4l-0.4,0.2c1,0,1.9-0.2,2.7-0.5c1.4,0,2.3,0.1,3.6,0
                                        c0.6,1,5.1-0.1,7.3-0.3c-0.4-1.4,2.5-1.5-0.3-2.3c0.4,0.1-0.4,0.2-1.8,0.5c-0.3,0.1-0.7,0.2-0.9,0.3c0,0,0.1-0.1,0.1-0.1
                                        c-1.4,0.2-3.3,0.5-5.4,0.8c1.7-0.9,3.4-1.7,5.9-1.3c1-1,2.1-2.1,2.8-2.6c3.2,1.5,9.2,1.8,10.2,2.8c6.6-1.7,15.7-3,19.4-6.3
                                        c1.8-0.7,2.3,0.3,3.1,0.6c-1,0.5-2.3,0.7-2.9,1.1c3.7-1.3,11.1-2.4,11.7-4.4c4.9-0.2-2.3,2.2,4.1,0.6l-0.8,0.7
                                        c6.7,0.1,11.7-2.2,16.8-4.6c5.1-2.3,10.3-4.7,17.8-4.8c-1,0.1-1.5,0.1-1.8-0.1c8.2-1.4,16.2-4.4,24.2-7.5
                                        c7.9-3.2,15.7-6.5,23.7-8.9c4.5-1.6,4.4-2.9,6.5-5l1.6,0.8c1.1-1.3,2.3-2.6,3.4-3.9c2.7-2.3,5.6-3.1,8.8-4.3c3.1-1.2,6.4-2.9,9-6.8
                                        l-1.5,0c0.6-0.5,1.4-1.2,2.2-1.9c0.8-0.8,1.6-1.6,2.3-2.3c0.7-0.8,1.3-1.4,1.8-1.8c0.5-0.4,0.8-0.6,1-0.3c0.1-0.4-0.1-1.5,1.1-2.1
                                        l0.7,0.4c1.3-2.4,2.5-4.9,3.4-7.4c1.7-2.6,2.2-2.1,3-1.9c0.4,0.1,0.7,0.1,1.3-0.5c0.3-0.3,0.6-0.7,0.9-1.4c0.3-0.7,0.8-1.5,1.1-2.8
                                        c0.4-1.8,0.3-4.6-0.1-6.6c-0.5-2-1.1-3.3-1.4-2.6c0,0.7-0.2,1.6-0.5,2.3c-0.3,0.7-0.7,1.2-1.1,1.2c0-0.5,0-1,0-1.5l-0.1-1.5
                                        c-1.1-0.3-2.3-1-3.4-0.7c-0.5,0.1-1.1,0.5-1.6,1.1c-0.5,0.6-1,1.6-1.7,2.9c0.2,1.4,0.3,2.9,0.3,4.4c-0.5,0.3-1,0.6-1.5,0.9
                                        c0.4-1.1,0.8-2.2,1-3.3c-0.4,0.4-0.6,0.8-0.8,1.1c-0.2,0.4-0.3,0.7-0.3,1.1c-0.1,0.7,0,1.5,0,2.3c-0.7,0.1-1.3,0.4-1.9,1
                                        c0,0.8,0,1.6-0.1,2.4c0,0.8-0.2,1.6-0.3,2.4c-1-0.1-0.4-2.6-2.4-0.5c-0.2,1.1,0.5,1,1.1,1c0.6,0,1,0.1,0.2,1.7
                                        c-1.1,0.6-1.7,0.5-2.2,0.7c-0.5,0.2-0.7,0.6-1.3,1.8c-0.7-0.1-0.6-0.8-0.1-1.7c-1,2.2-4,4.5-4.1,6.2c-1.3,0.3-2.8,1.6-4.1,2.7
                                        c-1.3,1-2.5,1.7-3.1,0.7c-0.1,0.4-0.7,1.4-1.5,2.4c-0.8,1-1.8,2.1-2.7,2.7c1-2.2-0.8-1.1-2.9,0.1c-2.1,1.2-4.6,2.5-4.8,1.1
                                        c-0.4,0.9-1.1,1.7-2.1,2.5c-1,0.8-2.2,1.5-3.5,2.3c-2.6,1.5-5.6,2.9-7.8,4.6c0.2-0.3,0.6-0.9,1.1-1c-2.8,1.1-3.9,0.8-5.9,1.1
                                        c0.4,1.9-3.8,4.2-5.7,6.2c-4.2-0.4,1.3-2.9-0.5-4.2c0.8,1.4-5.4,3.8-3.8,4.6c-3.1-0.1-2.5,0.3-4.4-0.7c0.3,2.4-2.4,1.3-4.1,3.5
                                        c-4.3,0.2-1.7-1.8-5.8,0.3C475.5,149.1,479.1,147,479.1,147z M373.6,172.7C373.6,172.7,373.6,172.7,373.6,172.7
                                        c0.9-0.2,1.7-0.3,2.2-0.4C375.3,172.4,374.7,172.5,373.6,172.7z M364.4,173.7c1.1-0.1,2.5-0.2,4-0.3c0.4,0.4,0.1,1-0.8,1
                                        C368,174,365.8,173.9,364.4,173.7z M384.9,181.1c-1.4,0.4-2,0.2-2.2-0.1c0.7-0.1,1.5-0.3,2.5-0.5c0-0.1,0.1-0.1,0.2-0.2
                                        C385.3,180.6,385.2,180.9,384.9,181.1z"/>
                                     <path class="st0" d="M387.3,179c0.2-0.1,0.3-0.1,0.6-0.2c-0.3-0.1-0.6-0.2-0.9-0.2c-0.2,0.2-0.3,0.3-0.5,0.5
                                        C386.8,179,387.1,179,387.3,179z"/>
                                     <path class="st0" d="M402.8,14.7l-0.2-0.1c0.2,0.1,0.3,0.2,0.4,0.3C402.9,14.8,402.9,14.8,402.8,14.7z"/>
                                     <polygon class="st0" points="237.7,173.9 237.7,174.3 238.5,174 	"/>
                                     <path class="st0" d="M451.8,29.2c0.1,0.1,0.2,0.3,0.6,0.5C452.3,29.6,452.1,29.4,451.8,29.2z"/>
                                     <path class="st0" d="M472.7,35.3l2.8,1.4C474.3,36,473.5,35.6,472.7,35.3z"/>
                                     <path class="st0" d="M488.4,41.2c0.3-0.2,0.4-0.3,0.5-0.5c0,0,0,0,0,0L488.4,41.2z"/>
                                     <path class="st0" d="M224.4,15.3c-0.2,0-0.4,0.1-0.6,0.1C222.3,15.7,223.3,15.6,224.4,15.3z"/>
                                     <path class="st0" d="M303.9,10.4c-0.9,0.2-1.7,0.5-2.6,0.6C302.7,11.2,303.8,11.2,303.9,10.4z"/>
                                     <path class="st0" d="M136.9,28C136.9,28,136.9,28,136.9,28c-0.2,0-0.3,0-0.5,0.1L136.9,28z"/>
                                     <path class="st0" d="M297.6,10.9c1.4,0.3,2.6,0.3,3.7,0.1C300.2,10.9,298.8,10.7,297.6,10.9z"/>
                                     <path class="st0" d="M547.7,78.1l0.4,0.6c0.1-0.1,0.2-0.1,0.2-0.1L547.7,78.1z"/>
                                     <path class="st0" d="M548.3,78.6l0.6,0.6C548.7,78.9,548.5,78.6,548.3,78.6z"/>
                                     <path class="st0" d="M550.1,80.5c-0.4-0.4-0.8-0.9-1.2-1.3C549.3,79.7,549.6,80.4,550.1,80.5z"/>
                                     <path class="st0" d="M533,63.1l-0.5,0.3C532.6,63.4,532.8,63.3,533,63.1z"/>
                                     <path class="st0" d="M527.4,59.6c0.6,0.5,1.3,1.2,2.1,2c0.8,0.8,1.6,1.5,2.6,2l0.4-0.2C531.2,63.4,529.2,59.6,527.4,59.6z"/>
                                     <path class="st0" d="M505.2,41.2c1.7,0.9,3.4,1.8,5.1,2.7c-0.7-0.9-1.6-2.1,0.8-1.1C505.2,38.2,512.1,44.4,505.2,41.2z"/>
                                     <path class="st0" d="M488.9,34c3,0.8,0.2-0.7,0.5-1C487.3,32.8,486.8,32.8,488.9,34z"/>
                                     <path class="st0" d="M363.3,8.7l0.4-1.2C361.3,7.4,363.6,7.9,363.3,8.7z"/>
                                     <polygon class="st0" points="338.4,13.3 340.6,14.2 341.8,13.1 	"/>
                                     <polygon class="st0" points="324.5,3.4 325.6,3.3 321.6,3.6 	"/>
                                     <path class="st0" d="M110,21.3l1.1,1.4C112.6,22,112.5,20.2,110,21.3z"/>
                                     <path class="st0" d="M24,58.4c-0.6,0-1.6,0.3-2.5,0.8c-0.9,0.5-1.7,1.3-2.1,2.1C21.1,60.1,23.2,59.2,24,58.4z"/>
                                     <polygon class="st0" points="212.4,184.8 210.5,185.2 214,185 	"/>
                                  </g>
                               </svg>
                            </span></Link>  
         </div> 
         </div>
         <div className="contactos">
             <div className="titulo">
             <Link to="/contact">
             <span className="palavra color-white inter-bold"> Contactos
               
                           
                              <svg version="1.1" id="Camada_1" className="linha"   x="0px" y="0px"
                                 viewBox="0 0 562 189.6"  >
                                 <g>
                                    <path class="st0" d="M556.2,95c0.3-0.4,0.1-1.4-0.7-1.2l-0.6,1.7L556.2,95z"/>
                                    <path class="st0" d="M550.8,91.9c-0.9,0.8-2,2.2-1.4,3.7c0.9-1.6,1.2-0.9,2.2-2.8c0.8-0.2,1.4,0.5,1.3,1.1c0.3-0.4,1.2,0.4,1.7-0.7
                                       c-1-1.3-2.4-1.3-3.7-0.3C550.5,92.7,550.7,92.2,550.8,91.9z"/>
                                    <path class="st0" d="M472.7,89.3l0.5,0.2C473.1,89.4,472.9,89.4,472.7,89.3z"/>
                                    <path class="st0" d="M424.3,88.4c0.4,0,0.5-0.2,0.5-0.4C424.5,88.2,424.2,88.3,424.3,88.4z"/>
                                    <path class="st0" d="M416,86.6c0.2,0.1,0.3,0.1,0.5,0.1C416.3,86.6,416.2,86.5,416,86.6z"/>
                                    <path class="st0" d="M472.7,89.3l-1.3-0.5C471.3,89.4,472.1,89.2,472.7,89.3z"/>
                                    <path class="st0" d="M540,95.9c0.3-0.1,0.5-0.2,0.7-0.4c-0.2,0.1-0.4,0.1-0.7,0.2C540.1,95.7,540.1,95.8,540,95.9z"/>
                                    <path class="st0" d="M494,88l0.4,0c0.1-0.2,0.2-0.4,0.3-0.7L494,88z"/>
                                    <path class="st0" d="M510.4,87.7c-0.4-0.1-0.5,1.3-0.6,2c0.2-0.6,0.5-0.9,0.8-1C510.6,88.5,510.5,88.1,510.4,87.7z"/>
                                    <path class="st0" d="M536.4,90.2l0.5-0.2C536.7,90.1,536.5,90.1,536.4,90.2z"/>
                                    <path class="st0" d="M482.3,87.7c0-0.2-0.1-0.4-0.1-0.5C482.2,87.4,482.2,87.5,482.3,87.7z"/>
                                    <path class="st0" d="M24.4,98.3c0-0.1,0-0.2,0-0.2C24.3,98.1,24.3,98.2,24.4,98.3z"/>
                                    <path class="st0" d="M5.4,91.3l0.3-0.1c0-0.3,0.1-0.5,0.1-0.8L5.4,91.3z"/>
                                    <path class="st0" d="M400.9,84.8C400.9,84.8,401,84.8,400.9,84.8C401.4,84.7,401.2,84.7,400.9,84.8z"/>
                                    <path class="st0" d="M262.1,85.8c1.6,1.3-2.6,1.6-0.2,3C261.7,88.3,264.1,86.7,262.1,85.8z"/>
                                    <path class="st0" d="M539.6,95.7c-0.2,0-0.4,0-0.7,0C539.2,95.8,539.4,95.8,539.6,95.7z"/>
                                    <path class="st0" d="M261.9,88.8C261.9,88.9,261.9,88.9,261.9,88.8l0.2,0.1C262.1,88.9,262,88.9,261.9,88.8z"/>
                                    <path class="st0" d="M366.1,85.6c0.1,0.2,0.2,0.4,0.4,0.6C366.4,86.1,366.3,85.9,366.1,85.6z"/>
                                    <path class="st0" d="M392,87.6l-0.6-0.2C391.6,87.6,391.9,87.6,392,87.6z"/>
                                    <path class="st0" d="M352.6,87.6c-0.3-0.1-0.5-0.3-0.9-0.1c0,0.1,0.1,0.1,0.1,0.2C352,87.5,352.2,87.4,352.6,87.6z"/>
                                    <path class="st0" d="M338.6,87.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.3-0.1,0.4-0.1L338.6,87.6z"/>
                                    <path class="st0" d="M67,97.6c-0.3-0.4-0.5-0.8-0.6-1.3C66.6,97.3,64.5,97.7,67,97.6z"/>
                                    <path class="st0" d="M540,95.1c-0.1,0.4-0.2,0.5-0.4,0.6c0.2,0,0.3,0,0.5-0.1C540.1,95.4,540.1,95.2,540,95.1z"/>
                                    <path class="st0" d="M352,98.7l-2.2-0.2C350.4,98.6,351.2,98.7,352,98.7z"/>
                                    <path class="st0" d="M167.6,97.4l-0.5,0.2C167.3,97.6,167.5,97.5,167.6,97.4z"/>
                                    <path class="st0" d="M343.2,98.7c-0.4-0.5-0.8-0.8-1.3-1c-0.1,0.1-0.2,0.2-0.3,0.4L343.2,98.7z"/>
                                    <path class="st0" d="M66.4,96.3C66.4,96.3,66.4,96.3,66.4,96.3C66.4,96.3,66.4,96.3,66.4,96.3z"/>
                                    <path class="st0" d="M51.7,99.6c2.1,0,1-1.8,1.4-2.9c2.5-0.5,1.2,1.4,2,2.3c1.6-0.5,2.8-2.2,4-2.1c0.1,0.3,0.3,0.4,0,0.8
                                       c1.7-1.5,5-0.1,6.9-1.9c0.1,0.2,0.2,0.3,0.3,0.5c-0.3-0.9-0.4-1.9-0.3-2.5c1,0,1.8-1.2,2.1,0c-0.6,0-0.6,0.5-0.9,0.8l1-0.5
                                       c0.9,1.7-1.4,0.6-1.4,1.9c1.9-0.6,4.2-2,5.7-1.5c0.3,1.7-3.5,0.6-1.5,2.1l-2.3-0.6c1.8,2.7-1.4-0.2,0.2,2.7c3,0.5,3.4-0.9,6.4-0.4
                                       c0.7-0.9-0.2-1.4,0.5-2.3c4.3-1.7,3.4,4.3,6.6,2c0.8-1.1,1.1-3.3,3.4-2.1l-0.8,1.9c2.9,0.2,1.7-2.8,4.8-1.5
                                       c-0.5,0.8-2.3,0.6-1.9,0.9c2,1.5,1.4-1.1,2.9-1.1l0.3,1.7c3.1-0.3,6.6-1.5,10-1.8c-0.2,0.5-0.8,1,0,1.6c4.4-0.8,9.3,0.2,13.5,1.2
                                       l1.6-1.5c0.4,0,0.7,0.7,0.3,0.9c1.6,0.6,0.1-0.9,0.9-1.5c0.9-0.1,1.2,0.8,0.6,1.3c2.9-1.2,8.3-2.7,11.3-1.1l-0.6,0.4
                                       c2.4,0.6,2.7-3,4.9-1.6l-0.4,0.3c5.2,0.6,10.9-0.7,14.8,1.5c0.7-2.1,5.1,0.9,5.4-2c2.9,1.5,2.1,2,6.3,3c1.1,0.1,1.7-1.8,3-2.1
                                       l0,1.5l1.9-1.6l1,1.8l1.3-0.4c-0.5-0.1-1.1-0.7-1-1.1c2-0.7,4.9,1.1,4.9,1.9l4.8-1.3c-0.1,0.6-0.9,0.8-1.5,1.2
                                       c2.5-1.6,0.5,1.6,2.8,0.2c-0.3-0.3,0.2-0.9,0.2-1.2c1.2,0.9,3.8,0.9,5.7,0.5l-0.4,1.1c3.5,0.3,7.4-0.5,11.4-0.5
                                       c-1.4-0.5-1.1-2.3,0.1-2.5l0.5,1.6c0.7-1-1.2-0.6,0.3-1.8c1.5,0.2,1,1.9,0.1,2.4l1.8-0.6c0.1,0.5-0.3,0.7-0.4,1.4
                                       c0.7-1,3.1,1.6,4.2-0.1l0.1,0.5c4,0,5.7-0.5,9-0.6c0.1-1.4,1.3-1.1,2.3-2.2c2.4,0.4,5,1.6,7.8,1.4c2-0.7,0.6-1.2,1.5-2
                                       c0.1,1.3,3,1.2,1.5,2.1c3.4-0.2,4.4-0.3,8.7-1.3c-0.5-0.5-1-0.8-0.7-1.2c0.8-0.2,1.8,0.5,1.7,1.2l-0.2,0.1
                                       c4.9-1.9,11.2,3.7,15.1,1.1l-0.2,0.1l4-1.4c1.8,0.2-1.1,1,0.3,1.8c4.3-2.1,6.8,0.3,10.4-1.1c2.8,3.8,9.8-0.3,13.5,2.1l-0.2-0.9
                                       c1-0.9,1.2,0.5,1.9,0.7c-0.1-0.7-1-0.8-0.4-1.3c8.2-1.1,16.8,1.3,25.2,2.1c3.1,0.2,1.7-4.6,5.6-2.5l-1.1,1.7
                                       c6.2-2,12.6,0.1,18.7-1.6c-0.4,0.4-0.4,0.9-1,0.9c1.3,1.2,4.7,0.1,4.7-0.9c0.9,0.1,0.2,0.9,0.8,1.4c1.3,0.7,3.2-1.5,3.9-0.3
                                       c0.1,0.2-0.4,0.4-0.6,0.5c0.8-0.6,3.9,0.1,3.1-1.2c2.3,0.7,4.3-0.6,6.1,0.2c1.2-1.5,3.9,0.7,4.3-1.4c-0.2,2.3,4,1.8,5.4,0.8
                                       c-0.5,0.7,1.2,0.7,0.7,1.7c1.6,0,3.1-0.5,2.8-1.6c3,2.7,6.1-2.2,7.8,1.3c4.9-1.2,9.4-2,13.7-2.6c-0.2,2.3-4.8,0.2-4.6,2.6
                                       c4.1-0.9,6.5-2.9,10.6-2.3c1.1,0.3-0.6,1-1,1.4c4.1-0.9,8,0.2,11.7-1c0,0.2-0.4,0.4-0.8,0.6c0.7-0.1,1.7,0.3,1.6-0.7l-0.7,0.3
                                       c-1.2-1.5,2.9-3.1,3-3.9c-1.2,2.9,3.6,0.7,1.7,4.5c0.5-0.2,1.1-0.7,1-1.4c0.7,0.2,0.9,0.6,0.2,1.4c2.6-0.6,1.7-1.6,4-1.6
                                       c0,0.5-0.5,0.7-0.7,1.3c1.2-1,2.4-1.9,4.4-1.5c-0.1,0.3,0.1,0.7-0.5,0.7c3.4-0.1,5.8-2.6,9.6-1.6c-1.1,2.2,1.3,0.7,1.5,2.6l2-0.6
                                       l-0.8-0.9c1.8,0,3.3-1.3,4.9-0.7l-1.3,0.8l3.3-0.4l-1.9-0.7c1.8-1-0.2-2.4,2.2-1.8c-0.5,0.2,1.8,0,2.7,1.1c0,0,0,0,0,0
                                       c0.9-0.6,1.8-1.2,3.1-0.8c0.3,0.6-0.3,2.1-0.1,2.5c0.9-1.6,4.1-0.4,5.2-2.1c-0.4,0.4-0.4,1.4-0.3,1.6l0.7-1.3
                                       c0.8,0.4,0.9,0.6,0.8,1.4c2.5,0.2-0.8-2.4,2.1-1.3c-0.2,0.1-0.1,0.3-0.6,0.5c2.9,0.5,5.7-0.8,8.4-0.2c0.8,1.9-2.4,0.5-1.4,2
                                       c3.9-0.3,8.1-2.3,11.8-1.8l-0.7-0.2c0.5-2.7,2.8,1.3,4.7,0.1c-0.5,0.2-0.6,1.5-0.5,1.2c2.6,0.4,5.3-0.7,9.2-1.5l-0.5,0.7
                                       c1.5,0.6,4,0.4,5.6,0.7c0.5-0.7,1.4-0.6,0.9-1.6c5.1,3.9,9.7-2.1,14.1,1.2c-0.5,0-1.3,0.6-0.9,0.6l3.8-0.6
                                       c-0.3-1.2-0.5-0.7-1.3-1.6c0.5-0.7,1.6-1.2,2.5-1.2c-1.7,0.8,0,1.9,0.8,2.5c-0.1-0.4,0.1-0.7,0.1-1.1c2,2.1,0.3-2.3,3.1-0.9
                                       l-0.1,0.6c1-0.3,2-1.1,3.1-0.9c0.5,1.3-1.1,0.6-1.2,1.7c1.5-0.1,3.1-2.5,4.5-1.2c-0.7,0.4-1.6,0.4-2.3,0.7c1.5,1.6,1.7,1.2,2.8,1.4
                                       l-0.1,0l2.6,0.7L508,95c1.9-3.5,3.9-0.6,6.5-2.9c-2.5,2.2-0.4,1.2-0.6,2.8c0.7,0.4,1.7,0.9,1.4,1.3c1.2-1.2,2.1,1,3.8-0.8
                                       c0.1,0.5,1.3,0,1,1.3c1.6-0.4-0.4-0.8-0.2-1.5c2.5-1.7,5.8,2,9.4-0.3c3.1-0.9,3.6-2.6,6.5-3.4c1,0.8-0.8,1.8-0.8,1.8
                                       c1.5,0.7,2.7,0.6,4.2,0c-0.3,0.4,0,0.8,0.5,1.3c1.2-0.1,0.8-1.8,2.3-1.1c-0.3,0.5-0.6,1.4-1.2,2c1.4-0.4,2.7-1.4,4.2-1.8
                                       c0.4,0.8-0.9,1.3-1.4,1.8c1.6-0.4,4.2-0.8,4.7-2.1l-1-0.5c0.6-0.1,0.3,1.2-0.1,1.4c-1.4,1.1-2-0.5-2.1-1l1-0.3
                                       c-1-2.9-4.1,0.5-6.1,0.3l0.9-2.1l-2.1,1.7c-0.4-0.5-0.8-1.7,0.3-2c-1.5-1-1.3,0.7-2.6-0.4c-0.1-0.2-0.1-0.3,0.1-0.4l-1.4,0.6
                                       c0.3-0.4-0.2-1.5,0.6-1.2c-2.6-1.2-4.1,1.6-5.1,0l0.2-0.1c-3.6,0.7-0.3,1.5-3,3.3l-1.8-3.8l-0.4,1.5c-0.4-0.1-1.1-0.2-1-0.8
                                       c-1.4,1,0.2,0.7-0.6,1.7c-1.8-1.4-4.9,0.7-5.3-1.8c-0.6,1.2,1.7,0.9-0.2,1.7c-0.5-2.6-2.2,0.4-3.8-0.8c0.5-1.3,2-0.6,0.4-1.8
                                       c-1,2.3-3.2-0.5-4.5-0.1c-0.1,1.8-3,0.8-5.3,1.4c0.1-0.6-4.3-1-6.4-1.7l0.1-0.3c-0.4,1.8-2.6,1.5-4.2,1.9c0.1-1.1,0.4-1.5,1.2-2
                                       l-1.5,0c-0.4,0.6-0.8,0.9-1.8,1.1l0.4-1.4c-2,0.1-4,1.6-6.6,0.7c-0.1,1.3,2.1,3.6-0.7,4.4c-0.1-0.7-0.2-1.9,0.5-2.2
                                       c-0.2,0.1-0.8,0.6-1.3,0.3l1.1-1.2c-1.2-0.5-0.6,1-1.6,0.9c0-1-0.7-0.7-0.6-1.5c0.2-0.1,0.8,0.4,1.2,0c-1.1-0.2-2.3-0.8-2.8-1.5
                                       c0.1,0.6,0,1.3-0.8,1.5c-2,0.1-0.7-1.2-1.9-1.7c-0.5,0.7-1.8,0-1.8,1.5c-0.6,0-0.7-0.7-0.9-1.1c-1,0.7-4.8,0.2-3.7,1.8l0.3,0.1
                                       c0,0-0.1,0-0.2,0.1c0,0,0,0,0,0h0c-3.4,1.5-7.1-0.8-10.4-0.2l0-0.5c-2,0.6-4.5,0.3-5.9,1.4c-0.6,0-1.4-0.9-1.2-1.5
                                       c-1.5,0.4-1.7,0.9-3.9,0.1c1.5-0.9-0.5-0.8,1.6-0.6c-2-0.4-2.5-1.2-4.6,0.1c0.7-1.3-1.5-1.6-2.5-1.2l1.4,0.9c-1.1,0.2-2,0.1-3,0
                                       l0.5-1.7c-4.1-2-7.4,3.4-10.5,0.1c-1.1,1.2,2.2,0.8,1.3,2.2c-1.9-0.2-3.8-2.6-4.4-2.6c-2.6-0.9-2.4,2.4-5.1,1.8
                                       c0.3,0.6,0.7,2.2-1.3,2.7c-1.9-0.2-2.3-2.5-1.1-3c0.5,0,0.7,0.1,0.7,0.3c0.6-0.2,1.4-0.5,0.7-1.1l-0.2,0.6
                                       c-0.9-0.6-3.5-0.5-2.9-1.5c-1.4,0.6-0.6,1,0.4,1.3c-2.4-0.9-3.8,0-6.3-0.6c0.4,0.3,0.8,1,0.4,1c-3.6-0.7-1.6,1.1-4.1,1.4
                                       c-1.4-0.9,0.5-2.2-2-1.4c-1.5-0.6-0.3-1.6,0.3-2.1c-2.3,1.1-4.2-0.8-5.5-1l1.2-0.5c-0.7,0.3-1.3,0.3-1.9,0.3l0.7,1.2
                                       c-0.7-0.2-1.3-0.2-1.3-0.7c-0.7,0.8,0.6,2-0.9,3c-0.9-0.6-2.8,0.6-3.2-0.9c2.9,0.5-0.3-1.6,2.2-1.8c-0.5,0.2-1.1,0.2-1.8,0
                                       c-0.1-0.2,0.1-0.3,0.3-0.4c-2.8-1.4-1.3,1.8-4.2,1.7c0.4-0.9-0.7-1.7-1.2-1.5c1.2,0,0.6,1.5-0.5,2.2c-1.9-0.7-2,0.5-3,0.4l1.1,0.3
                                       c-0.4,0.9-1.3,0.8-2.6,1c-0.1-0.7,1.2-0.5,0.7-0.8c-1.4,1.6-3.3-1.6-5.3-0.6c-0.9-0.6-0.1-1.7-0.9-2.1c-2.3,1.2-2.4-1.2-4.8-0.3
                                       c0.7,1.2,0.8,0.9-0.9,2.1l3.9-0.8l-2.5,1.7c1.2,0,2.5-0.8,3.2-0.5c-1.2,1-1.1,0.7-0.5,1.7c-1.6-1.8-3.2,1-5.1-0.2l0.1-3
                                       c-2.8-1.3-5,1.5-8.6,0.8l1.2,0.5c-0.5,1.2-2.2,0.2-3.4,0.6c0.1-1-0.6-1.3-1.1-2c0.1,0.7-2.7-0.1-3,1.9l-1.6-1.3
                                       c-2.9-0.5-3.4,2.8-6.2,2.5c0.9-1.1-0.4-1.8,1.5-2.5c-0.9,0-1.8-0.2-1.8,0.5c-0.6-0.6-2.6,1.3-3.5,0.4c-0.4,0.4-0.5,1.5-1.6,1.4
                                       c-0.1-0.2,0-0.5,0-0.5c0,0.5-1.7,0.7-0.7,1.8c-2.9-3.1-8.1,0.2-10.7-2.4c-2.1,0.3-4.5,0.6-6.4,0.7c0.1-0.3,0-0.5,0.4-0.4
                                       c-2.6-0.9-1.6,2.8-4.5,1.8c-1.5-1.6,1.6-0.9,0.6-1.5c-0.4-3.2-3.5,0.4-5.5-1.5l0.5-0.2c-1.5-1.1-3.7,1.2-6.1,1c0,0,0.1-0.3,0-0.5
                                       c-1.7,1.7-4.9,2.2-7.3,2.7c0.7-1.3,1.1-0.7,0.6-2c-1.1,0.2,0.3,2.1-1.8,2.4c-0.7-0.7-2.2-1.3-2.4-2.7l2-0.1c-1-1.8-2.6,0.1-3.6-0.2
                                       l0.1-0.8c-3.1,0.3-3.1,2.2-6.4,2.1l0.3,0.6c-1.8,1.9-1.5-1.1-3.3,0.4l-0.5-2c-1.2,0.5-4.2,1.9-6.3,2.3c1.2-1.5,3.7-2.2,5.2-3.1
                                       c-1.2,0-4,0.1-4.6,1.1c0.5-0.2,1.1-0.7,1.5-0.4c-1.4,1.6-3.3,2.3-5.7,2.3c0.4-3.8-6.1-1-7.8-3.9c-4.2,1.4-8.2-0.6-13.1-0.4
                                       c1.7,3-1.4,0.1-1.1,3.1c-1.1,0.2-1.6,0.1-1.7-0.1l-3.4-1.8c-0.8-0.9,1.1-0.7,1-1.4c-2.5-0.2-1-1.3-2.7-2.1c0.3,1.1-0.9,1.6-2.3,1.1
                                       l2,1.4c-3.5,1.6-3-2.6-6.5-1l1.1-1c-1.8,0.6-6.6,0-8.3,2.4c-0.3-0.4-0.7-1.2,0.1-1.4c-4.3-0.4-9.5,2.6-12.8,1.1l0.3-0.7
                                       c-0.8,0.2-1.4,1.4-2.4,0.7c0.1-0.3,0.3-0.7,0-0.8c-0.4,0.3-2.1,1.3-3.2,0.9l0.9-0.8c-4.2-1-6.4,2-9.9,2.6c0-2.2-3.3-2.9-4.9-3.3
                                       l0.1-0.3c-4.8-0.3-8.2,1.8-12.7,2.7c-2.8-2.4-9.4-1-13.9-2.3c0.7,0.4-0.1,1.7-0.9,1.9c-1.1-0.4-2.9,1.5-2.7-0.5l0.4,0
                                       c-0.8-2-2.9-1.5-4.6-1.5l-0.3,2.6c-4.4-3.8-11.5,1.4-14-2c-1.1,0.7-2.4,0.7-3.4,1.3l0.1-1.4c-2.9-0.4-4.4-0.5-8.1-0.2l0.7-1
                                       c-2.1-0.1-2.6,4.1-4.5,4.2l-0.6-2.1c-3.6,0-7.2-1.8-10.8,0.1c0.1-0.3,0.2-0.9,0.7-1c-1.7-0.1-6.5-1.4-5.9,1.5
                                       c0-1.9-3.7-0.2-5.6,0.6l0.3-1.4c-1.6,1.8-1.7,2.3-4,2.8c-0.5-0.2-0.5-1.1,0.2-0.8c-1.8-0.7-1.4,1.3-3.6,0.6l0.8-0.9
                                       c-1.9-0.1-1.7,0.4-2.7,1.4c-1.2,0.6-3.5-0.4-3.8-1.6c-0.2,0.8-1.3,1.8-2.4,1.4c-0.1-0.5,0.3-0.8,0.5-0.9c-3.1-0.9-4.6,1.6-6.7,0.3
                                       c0-0.5-0.2-0.7-0.4-0.8c2.5,0,4.4,0.1,4.3,0.2c0-1.3-2-1.5-0.4-3.3c-1.6,1-4.3,3.1-6.3,2.5c-0.1-0.1-0.2-0.2-0.3-0.4l0.2-0.1
                                       c-0.4-0.1-0.8,0-1.2,0.2c-0.6-0.2-1-0.4-1.6-0.4c-0.2-1.1-2.3-0.6-3.3-0.6c0.1,1.4-1.2,1.2,0,2.3c-0.2-0.1,0.2-0.2,0.8-0.2
                                       c0.1,0,0.3-0.1,0.4-0.1c0,0,0,0.1-0.1,0.1c0.7,0,1.5-0.1,2.5-0.1c-0.8,0.7-1.6,1.3-2.7,0.5c-0.5,0.9-1.1,1.8-1.4,2.3
                                       c-1.4-2-4-3-4.3-4.2c-3,0.8-7.1,0.5-9,3.2c-0.9,0.3-1-0.8-1.3-1.2c0.5-0.3,1.1-0.2,1.4-0.6c-1.7,0.7-5.1,0.3-5.5,2.1
                                       c-2.2-0.8,1.2-1.7-1.8-1.4l0.4-0.6c-5.9-2.9-9.5,4.7-16,0.9c0.5,0.2,0.7,0.3,0.8,0.6c-7.4-1.7-15.3,1.6-22.6-1.1
                                       c-2.1-0.5-2.3,0.6-3.6,1.5L32.1,88l-2.2,1.7c-3.1,1.3-5.3-3.7-9.2-1.3l0.5,1c-1.4-0.2-4.4,0.6-4.2-0.6c-0.1,0.2-0.5,1.1-1,0.5
                                       l0-0.8L12.5,90c-2.7-0.6,0.4-3.1-3.5-3.3C7.6,87,4.9,89,5.5,89.5C6,89.6,6.9,90,7,90.8l-1.3,0.4c-0.2,2.3-1.3,4.7,2,6.3l2.3-1.4
                                       l0.7,1.1l-1.9,0.1c1.1,1.1,1.8,0.1,2.5-0.5c0.2,0.6,0.5,1,0.9,1.2l2.2-2.2c0.3,0.9-1,1.8,0.5,2.3c1-1-1-2,0.7-2.7
                                       c1.1,1.1,0.8,1.9,2,1c0.2,0.6-0.1,1-0.6,1.2c1.1-0.7,3-0.3,3.6-1.5c1.1,1.3,3.8-0.3,3.7,1.9c0.4-0.5,2-1.7,3-1.8
                                       c-1.9,2.6,3.9,0.2,3.4,3c1.4-2.4,5.1-1.1,7.6-2.4c-0.1,0.2-0.4,0.6-0.6,0.4c1.4,0.2,1.8,0.9,2.7,1.4c0.2-2,2.4-2.4,3.5-3.6
                                       c1.7,1.9-1,2.3-0.5,4.1c-0.1-1.6,3-1.6,2.4-2.9c1.3,1.2,1.1,0.6,1.8,2.2c0.2-2.5,1.2-0.4,2.3-1.9c1.9,1.2,0.5,2.3,2.6,1.5
                                       C53.5,98.8,51.7,99.6,51.7,99.6z M101.3,95.4C101.3,95.4,101.3,95.4,101.3,95.4c-0.4,0.1-0.8,0.1-1,0.1
                                       C100.5,95.5,100.8,95.5,101.3,95.4z M105.5,95.4c-0.5-0.1-1.1-0.1-1.8-0.1c-0.1-0.5,0-1,0.4-1C103.8,94.7,104.8,95,105.5,95.4z
                                       M96.6,85.6c0.7-0.2,0.9,0.1,1,0.4c-0.3,0-0.7,0.1-1.2,0.2c0,0.1-0.1,0.1-0.1,0.2C96.4,86.1,96.5,85.8,96.6,85.6z"/>
                                    <path class="st0" d="M95.4,87.5c-0.1,0-0.1,0.1-0.3,0.1c0.1,0.1,0.3,0.2,0.4,0.3c0.1-0.1,0.2-0.3,0.2-0.4
                                       C95.7,87.5,95.6,87.5,95.4,87.5z"/>
                                    <path class="st0" d="M473.3,89.6L473.3,89.6c0,0,0.1,0.1,0.1,0.2C473.3,89.7,473.3,89.6,473.3,89.6z"/>
                                    <polygon class="st0" points="163,98.1 163,97.7 162.6,98 	"/>
                                    <path class="st0" d="M496.2,94.4c0,0.1,0.1,0.3,0.3,0.4C496.5,94.7,496.4,94.5,496.2,94.4z"/>
                                    <path class="st0" d="M506.2,94.7l1.4,0.5C507,94.9,506.5,94.8,506.2,94.7z"/>
                                    <path class="st0" d="M513.8,95.4c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0,0,0,0L513.8,95.4z"/>
                                    <path class="st0" d="M392.6,97.1c-0.1,0-0.2,0-0.3,0.1C391.6,97.4,392,97.3,392.6,97.1z"/>
                                    <path class="st0" d="M428.6,93.8c-0.4,0.3-0.8,0.5-1.2,0.7C428.1,94.5,428.6,94.6,428.6,93.8z"/>
                                    <path class="st0" d="M352.3,98.7C352.3,98.7,352.3,98.7,352.3,98.7c-0.1,0-0.2,0-0.2,0L352.3,98.7z"/>
                                    <path class="st0" d="M425.8,94.4c0.6,0.3,1.2,0.2,1.7,0C426.9,94.3,426.3,94.2,425.8,94.4z"/>
                                    <path class="st0" d="M546.8,96l0.4,0.1c0-0.1,0-0.2,0-0.2L546.8,96z"/>
                                    <path class="st0" d="M547.2,95.9l0.4-0.2C547.5,95.7,547.3,95.7,547.2,95.9z"/>
                                    <path class="st0" d="M548.5,95.4l-0.9,0.4C547.9,95.7,548.3,95.8,548.5,95.4z"/>
                                    <path class="st0" d="M536.7,95.4l-0.1,0.5C536.7,95.8,536.7,95.6,536.7,95.4z"/>
                                    <path class="st0" d="M533.7,95.5c0.7,0.1,1.8,1.1,2.9,0.8l0.1-0.4C536.1,96.7,534.4,94.7,533.7,95.5z"/>
                                    <path class="st0" d="M520.9,89.2l2.6,0.4c-0.4-0.5-1-1.3,0.1-1.4C520.3,86.6,524.2,89.5,520.9,89.2z"/>
                                    <path class="st0" d="M512.9,88.4c1.4-0.3,0-0.8,0.1-1.1C512.1,87.8,511.9,88,512.9,88.4z"/>
                                    <path class="st0" d="M455.3,88.4l0.1-1.3C454.4,87.3,455.4,87.7,455.3,88.4z"/>
                                    <polygon class="st0" points="444.3,95.1 445.3,95.8 445.8,94.7 	"/>
                                    <polygon class="st0" points="437.8,85.9 438.3,85.8 436.5,86.2 	"/>
                                    <path class="st0" d="M341.1,86.4l0.3,1.6C342.1,87.7,342.3,85.9,341.1,86.4z"/>
                                    <path class="st0" d="M299.3,88c-0.5-0.8-1.6-1.3-2.4-0.5C297.9,87.6,298.8,88.2,299.3,88z"/>
                                    <polygon class="st0" points="174.1,85.4 174.9,84.9 173.3,85.3 	"/>
                                 </g>
                              </svg>
                           </span>
                           </Link>    
            </div>
         </div>
         </div>
      </div>
   </div>

   
      <Headroom style={{
  position: 'fixed',
  transform: 'translateY(0)',
  zIndex:'40'
}}>
      <div id="barra-nav">
        <div className="nav-contentorgeral">
       

          <div className="brand">
          <a href="https://www.farmin-trainings.net/">
            <img src={logo} alt="Logo" />
            </a>
          </div>
          <div className="ig">
          <a target="_blank" href="https://www.instagram.com/farmin.trainings/"> <img src={ig} alt="Logo" /></a>
          <a target="_blank" href="https://www.facebook.com/farminofficial"><img src={fb} alt="Logo" /></a>
          </div>
          
          <div className="menu">
         <button className="invisiblebtn" onClick={clickMenu}> <Hamburger  size={20} color="#ffffff" /></button>
          </div>
          
    
        
       
   
       
     
  
  </div></div>

    </Headroom>
  
    </div>
    
  )

}
export default Header